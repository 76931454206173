










































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { AuditFlowCreateOrEditProxyDto, AuditFlowDto } from "@/api/appService";
import AuditFlowDraggable from "@/views/auditFlow/components/auditFlowList/auditFlowDraggable.vue";

@Component({
  name: "AuditFlowList",
  components: {
    AuditFlowDraggable,
  },
})
export default class AuditFlowList extends Vue {
  key = "";
  auditFlowId = "";
  title = "";
  isLoaded = false;
  hasDefaultRule = true;
  auditFlows: AuditFlowDto[] = [];

  created() {
    if (this.$route.params.key) {
      this.key = this.$route.params.key;
    }
    if (this.$route.query.auditFlowId) {
      this.auditFlowId = this.$route.query.auditFlowId as string;
    }
    if (this.$route.query.title) {
      this.title = this.$route.query.title as string;
    }
    this.fetchData();
  }

  fetchData() {
    api.auditFlowService
      .getAll({ key: this.key, maxResultCount: 655350 })
      .then((res) => {
        this.auditFlows = res.items!;
        if (
          this.auditFlows.filter(
            (m) => m.ruleConfig === "" || m.ruleConfig === "[]"
          ).length > 0
        ) {
          this.hasDefaultRule = true;
        } else {
          this.hasDefaultRule = false;
        }
        this.isLoaded = true;
      });
  }

  createRule() {
    this.$router.push({
      name: "auditFlowConfiguration",
      params: {
        key: this.key,
      },
      query: {
        isSupportRules: "true",
        auditFlowId: api.guid,
      },
    });
  }

  createDefaultRule() {
    this.$router.push({
      name: "auditFlowConfiguration",
      params: {
        key: this.key,
      },
      query: {
        isSupportRules: "false",
        auditFlowId: api.guid,
      },
    });
  }

  goBack() {
    history.back();
  }
}
