



























































import { Component, Prop, Vue } from "vue-property-decorator";

import {
  AuditFlowScope,
  AuditMethod,
  AuditNodeCreateOrEditDto,
  RoleDto,
} from "@/api/appService";
import StepItem from "@/views/auditFlow/components/processConfiguration/stepItem.vue";
import { INodesBlock } from "@/utils/tree";

import draggable from "vuedraggable";

interface ScopeMapping {
  name: AuditFlowScope;
  label: string;
}

@Component({
  components: {
    draggable,
    StepItem,
  },
})
export default class EditAuditFlow extends Vue {
  @Prop({ required: true })
  rows!: any[];

  @Prop({ required: true })
  row: any;

  @Prop({ required: true })
  index!: number;

  @Prop({ required: true })
  roles!: RoleDto[];

  @Prop({ required: true })
  users!: any[];

  typeText = "审核类型";
  toolTipContent = "";
  typeArray: any[] = [
    {
      text: "任意一个审核通过",
      value: AuditMethod.AnyOnePassed,
      tooltip:
        "这其中只要有一个审核通过，则会跳转至下一个审核步骤。如果有任意一个审核拒绝，则审核也会自动结束。",
    },
    {
      text: "全部审核通过",
      value: AuditMethod.AllApproved,
      tooltip:
        "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。如果有任意一个审核拒绝，则审核也会自动结束。",
    },
    {
      text: "全部审核通过，且所有人都要审核",
      value: AuditMethod.AllPassed,
      tooltip:
        "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。即使有审核拒绝的，也不会立即结束，其他参与者也必须审核。此方式主要是为了保证所有参与者必须给出意见，以提高审批效率。",
    },
    {
      text: "全员参与审核、少数服从多数",
      value: AuditMethod.AllParticipateAudit,
      tooltip:
        "要求该步骤中的所有参与者必须参与审核。当全部审核完成后，如果通过的人数大于拒绝的人数，则视为审核通过，流程跳到下一步。要求：参与者数量必须为奇数，且不少于3名参与者。",
    },
  ];

  // 移除空行
  private removeEmptyRow() {
    this.rows = this.rows.filter((x) => x.items.length > 0);
  }

  drag = false;

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: "ghost",
    };
  }

  created() {
    if (this.row && this.row.items && this.row.items.length > 0) {
      this.row.type = this.row.items[0].auditMethod;
      this.getType();
    }
  }

  // 删除整行
  private deleteRow(index: number) {
    this.rows.splice(index, 1);
  }

  // 删除空节点
  private removeEmptyNode(index: number) {
    const result: INodesBlock[] = [];

    this.rows.forEach((r) => {
      result.push({
        items: r.items.filter((x: any) => {
          return !!x.userId || !!x.roleId;
        }),
      });
    });
    this.rows = result;
    this.removeEmptyRow();
  }

  // 选权限下拉结束
  private selectRole(value: number, item: AuditNodeCreateOrEditDto) {
    if (!value) {
      item.roleName = undefined;
      item.roleId = undefined;
    } else {
      item.roleName = this.roles.filter((x) => x.id === value)[0].displayName;
      item.roleId = this.roles.filter((x) => x.id === value)[0].id;
    }
  }

  // 选验证用户下拉结束
  private selectUser(value: number, item: AuditNodeCreateOrEditDto) {
    console.log(value, item);
    if (!value) {
      item.userName = undefined;
      item.userId = undefined;
    } else {
      item.userName = this.users.filter((x) => x.value === value)[0].label;
      item.userId = this.users.filter((x) => x.value === value)[0].value;
    }
  }

  private setType(value: any) {
    this.row.type = value;
    if (this.row && this.row.items && this.row.items.length > 0) {
      this.row.items.forEach((item: any) => {
        item.auditMethod = value;
      });
    }
    this.getType();
  }

  private getType() {
    let result = this.typeArray.filter((s) => s.value == this.row.type);
    if (result.length > 0) {
      this.typeText = result[0].text;
      this.toolTipContent = result[0].tooltip;
    } else {
      this.typeText = "审核模式";
      this.toolTipContent = "";
    }
  }
}
