





















import { Component, Vue, Prop } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {FetchAuditItems} from "@/views/myAudit/components/AuditTabPane.vue";
// import {AuditUserLogType} from "@/api/appService";

@Component({ name: "AuditTabOfActivityApply", components: { AuditTabPane } })
export default class AuditTabOfActivityApply extends Vue {
  get fetchData() :FetchAuditItems{
    return api.activityApply.getMyAll;
  }
  @Prop({ required: true, default: false })
  isInternal = false;

  handleAudit(dto: any) {
    return this.$router.push({
      name: "ActivityApplyDetail",
      params: { id: dto.id!.toString() },
    });
  }

  get queryParams() {
    return {};
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
