
































































import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import {
  TriggerRuleDto,
  TriggerRuleItemDto,
} from "@/views/auditFlow/components/triggerRules/triggerRules.vue";
import TriggerRuleItem from "@/views/auditFlow/components/triggerRules/triggerRuleItem.vue";
import {
  add,
  differ,
  getMutexByKey,
  getOperatorsByType,
  ITriggerRuleOperatorSettings,
} from "../../../../../triggerRuleOperatorSettings";
import { AuditFlowConditionValueType } from "@/api/appService";

interface OperatorsControlDto {
  index: number;
  currentOperator: string;
  type?: AuditFlowConditionValueType;
  operatorList: ITriggerRuleOperatorSettings[];
}

@Component({
  name: "TriggerRule",
  components: { TriggerRuleItem },
})
export default class TriggerRule extends Vue {
  @Prop({
    required: true,
    default: () => ({
      key: "",
      type: undefined,
      displayName: "",
      triggerRuleItems: [],
      valueSource: { source: "", bindValue: "", bindText: "" },
    }),
  })
  triggerRule!: TriggerRuleDto;

  @Prop({ required: true, default: 0 })
  index!: number;

  private isEmpty = false;
  private triggerRuleDto!: TriggerRuleDto;
  private displayAddButton = true;
  private operatorsControlList: OperatorsControlDto[] = [];

  created() {
    this.triggerRuleDto = {
      key: this.triggerRule.key,
      displayName: this.triggerRule.displayName,
      valueType: this.triggerRule.valueType,
      valueSource: {
        source: this.triggerRule.valueSource.source,
        bindText: this.triggerRule.valueSource.bindText,
        bindValue: this.triggerRule.valueSource.bindValue,
      },
      triggerRuleItems: [...this.triggerRule.triggerRuleItems],
    };

    if (
      this.triggerRuleDto &&
      this.triggerRuleDto.triggerRuleItems &&
      this.triggerRuleDto.triggerRuleItems.length > 0
    ) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

  @Watch("triggerRule", { immediate: true, deep: true })
  triggerRuleValueChange() {
    if (this.triggerRule) {
      this.triggerRuleDto = {
        key: this.triggerRule.key,
        displayName: this.triggerRule.displayName,
        valueType: this.triggerRule.valueType,
        valueSource: {
          source: this.triggerRule.valueSource.source,
          bindText: this.triggerRule.valueSource.bindText,
          bindValue: this.triggerRule.valueSource.bindValue,
        },
        triggerRuleItems: [...this.triggerRule.triggerRuleItems],
      };

      this.initOperatorsControlList();

      this.$forceUpdate();
    }
  }

  @Watch("triggerRuleDto", { immediate: true, deep: true })
  triggerRuleDtoValueChange() {
    this.$emit("triggerRuleValueChange", this.index, this.triggerRuleDto);
  }

  removeTriggerRule() {
    this.$emit("removeTriggerRule", this.index);
  }

  initOperatorsControlList() {
    this.operatorsControlList = [];
    if (
      this.triggerRuleDto &&
      this.triggerRuleDto.triggerRuleItems &&
      this.triggerRuleDto.triggerRuleItems.length > 0
    ) {
      let index = 0;
      this.triggerRuleDto.triggerRuleItems.forEach((m) => {
        let operatorList = getOperatorsByType(this.triggerRuleDto.valueType);
        if (index > 0) {
          let lastOperatorsControl =
            this.operatorsControlList[this.operatorsControlList.length - 1];
          operatorList = this.getNextOperatorList([], lastOperatorsControl);
        }
        this.operatorsControlList.push({
          index: index,
          currentOperator: m.operator!,
          type: this.triggerRuleDto.valueType,
          operatorList: operatorList,
        });
        index++;
      });
    }
  }

  addTriggerRuleItem() {
    if (this.isEmpty) {
      this.isEmpty = false;
      this.triggerRuleDto.triggerRuleItems = [
        {
          operator: "",
          operatorText: "",
          value: "",
        },
      ];
      this.operatorsControlList.push({
        index: 0,
        currentOperator: "",
        type: this.triggerRuleDto.valueType,
        operatorList: getOperatorsByType(this.triggerRuleDto.valueType),
      });
      console.log(
        "operatorList: " + getOperatorsByType(this.triggerRuleDto.valueType)
      );
    } else {
      let lastOperatorsControl =
        this.operatorsControlList[this.operatorsControlList.length - 1];
      if (!lastOperatorsControl.currentOperator) {
        this.$message.warning("请选择前面一项的操作符");
        return false;
      }
      this.operatorsControlList.push({
        index: lastOperatorsControl.index + 1,
        currentOperator: "",
        type: this.triggerRuleDto.valueType,
        operatorList: this.getNextOperatorList([], lastOperatorsControl),
      });
      this.triggerRuleDto.triggerRuleItems.push({
        operator: "",
        operatorText: "",
        value: "",
      });
    }
  }

  operatorChange(
    index: number,
    key: string,
    displayName: string,
    type: AuditFlowConditionValueType
  ) {
    //直接利用索引设置值，不会触发监听
    // this.triggerRuleDto.triggerRuleItems[index].operator = key;
    // this.triggerRuleDto.triggerRuleItems[index].operatorText = displayName;
    const triggerRuleItem: TriggerRuleItemDto = {
      operatorText: displayName,
      operator: key,
      value: this.triggerRuleDto.triggerRuleItems[index].value,
    };
    this.triggerRuleDto.triggerRuleItems.splice(index, 1, triggerRuleItem);

    this.triggerRuleDtoValueChange();

    this.operatorsControlList.filter(
      (m) => m.index == index && m.type == type
    )[0].currentOperator = key;
    console.log(
      "currentOperator: " +
        this.operatorsControlList.filter(
          (m) => m.index == index && m.type == type
        )[0].currentOperator
    );
    this.setOtherOperatorList(
      this.operatorsControlList.filter(
        (m) => m.index == index && m.type == type
      )[0]
    );
    this.setAddButtonDisplay();
  }

  valueChange(index: number, value: any) {
    const triggerRuleItem: TriggerRuleItemDto = {
      operatorText: this.triggerRuleDto.triggerRuleItems[index].operatorText,
      operator: this.triggerRuleDto.triggerRuleItems[index].operator,
      value: value,
    };
    this.triggerRuleDto.triggerRuleItems.splice(index, 1, triggerRuleItem);
    this.triggerRuleDtoValueChange();
  }

  getNextOperatorList(
    selfOperatorList: ITriggerRuleOperatorSettings[],
    operatorsControl: OperatorsControlDto
  ) {
    if (selfOperatorList.length > 0) {
      return differ(
        selfOperatorList,
        getMutexByKey(
          operatorsControl.currentOperator,
          operatorsControl.type!,
          false
        )
      );
    } else {
      return differ(
        operatorsControl.operatorList,
        getMutexByKey(
          operatorsControl.currentOperator,
          operatorsControl.type!,
          false
        )
      );
    }
  }

  setAddButtonDisplay() {
    if (
      this.operatorsControlList.filter((m) => m.operatorList.length == 0)
        .length > 0
    ) {
      this.displayAddButton = false;
    } else {
      let lastOperatorsControl =
        this.operatorsControlList[this.operatorsControlList.length - 1];
      let nextOperatorList = this.getNextOperatorList([], lastOperatorsControl);
      if (nextOperatorList.length > 0) {
        this.displayAddButton = true;
      } else {
        this.displayAddButton = false;
      }
    }
  }

  setOtherOperatorList4Remove(operatorsControl: OperatorsControlDto) {
    let otherOperatorsControlList = [
      ...this.operatorsControlList.filter(
        (m) =>
          m.index != operatorsControl.index && m.type == operatorsControl.type
      ),
    ];
    if (otherOperatorsControlList.length > 1) {
      //setOtherOperatorList
      otherOperatorsControlList.forEach((other) => {
        this.operatorsControlList.filter(
          (m) => m.index == other.index && m.type == other.type
        )[0].operatorList = add(
          this.operatorsControlList.filter(
            (m) => m.index == other.index && m.type == other.type
          )[0].operatorList,
          getMutexByKey(
            operatorsControl.currentOperator,
            operatorsControl.type!,
            true
          )
        );
      });
    } else if (otherOperatorsControlList.length == 1) {
      otherOperatorsControlList[0].operatorList = getOperatorsByType(
        operatorsControl.type
      );
    } else {
      this.isEmpty = true;
    }
  }

  setOtherOperatorList(operatorsControl: OperatorsControlDto) {
    let otherOperatorsControlList = [
      ...this.operatorsControlList.filter(
        (m) =>
          m.index != operatorsControl.index && m.type == operatorsControl.type
      ),
    ];
    if (otherOperatorsControlList.length > 0) {
      //setOtherOperatorList
      otherOperatorsControlList.forEach((other) => {
        this.operatorsControlList.filter(
          (m) => m.index == other.index && m.type == other.type
        )[0].operatorList = this.getNextOperatorList(
          this.operatorsControlList.filter(
            (m) => m.index == other.index && m.type == other.type
          )[0].operatorList,
          operatorsControl
        );
      });
    }
  }

  removeTriggerRuleItem(index: number, type: AuditFlowConditionValueType) {
    this.triggerRuleDto.triggerRuleItems!.splice(index, 1);

    let deleteOperatorsControl = [
      ...this.operatorsControlList.filter(
        (m) => m.index == index && m.type == type
      ),
    ][0];
    this.setOtherOperatorList4Remove(deleteOperatorsControl);

    let controlIndex = this.operatorsControlList.indexOf(
      deleteOperatorsControl
    );
    if (controlIndex > -1) {
      this.operatorsControlList!.splice(controlIndex, 1);
      //通过下标更新index
      this.updateIndex();
    }
    this.displayAddButton = true;
  }

  updateIndex() {
    [...this.operatorsControlList].forEach((m) => {
      m.index = this.operatorsControlList.indexOf(m);
    });
  }
}
