












































import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfFundAppropriateApply",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfFundAppropriateApply extends Vue {
  typeList: any = [];
  queryForm = {
    userName: "",
    surname: "",
    fundName: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  get fetchData() {
    return api.fundAppropriateApply.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "fundAppropriateApplyDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundAppropriateApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
