






















import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import RejectDialog from "@/components/RejectDialog/index.vue";
import api from "@/api";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import { AuditFlowScope, PagedResultDto } from "@/api/appService";
// import {AuditUserLogType} from "@/api/appService";

interface IAuditDto {
  auditFlowId: string;
  auditStatus?: number;
  id: string | number;
}

// interface IAuditHostTypeGet{
//   getHostType(rowItemDto:any):AuditUserLogType;
// }

export interface AuditDataPagedResultDto {
  items?: any[];
}

export type FetchAuditItems = (
  requestParams: any
) => Promise<AuditDataPagedResultDto>;

@Component({
  name: "AuditTabPane",
  components: {
    RejectDialog,
    AgileAuditTag,
  },
})
export default class AuditTabPane extends Vue {
  @Ref() rejectDialog!: RejectDialog;
  @Ref() backDialog!: RejectDialog;

  @Prop({ required: true })
  fetchDataFunction!: FetchAuditItems;

  @Prop({ required: false })
  queryParams!: any;

  loading = true;

  tableItems: [] = [];

  created() {
    this.fetchData();
  }

  fetchData() {
    this.loading = true;
    const requestData = Object.assign(
      { maxResultCount: 100 },
      this.queryParams ?? {}
    );

    const promise = this.fetchDataFunction(requestData);
    console.log('<AuditTabPane> fetchData:',promise, this.fetchDataFunction);
    promise.then((res: any) => {
      this.tableItems = (res as any).items!;
      this.loading = false;

      this.$emit("count-changed", this.tableItems.length);
    });
  }

  handleAudit(itemDto: any) {
    this.$emit("audit", itemDto);
  }

  handleLogs(itemDto: any) {
    this.$emit("auditFlowDetail", itemDto);
  }
}
