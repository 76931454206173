



























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import { AuditFlowScope } from "@/api/appService";
// import {AuditUserLogType} from "@/api/appService";

@Component({
  name: "AuditTabOfApplicationPayment",
  components: { AuditTabPane },
})
export default class AuditTabOfApplicationPayment extends Vue {
  get fetchData() {
    return api.applicationPayment.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "officeApplicationPaymentDetail",
      params: { id: dto.id!.toString() },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    let scope: AuditFlowScope = dto.auditFlowScope;

    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope[scope],
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });

    // this.$router.push({
    //   name: "auditFlowDetail",
    //   params: {
    //     hostType: AuditFlowScope.FundProjectModifyApply,
    //     hostId: dto.id,
    //     auditFlowId: dto.auditFlowId
    //   }
    // });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
