var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-tabs',{attrs:{"type":"border-card"}},[(_vm.hasAuditPermission('Pages.InternalProjectProposal:Audit'))?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 立项书 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-project']}})],1),_c('audit-tab-of-internal-project',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-project', $event)}}})],1):_vm._e(),(
        _vm.hasAuditPermission(
          'Pages.InternalProjectBeneficiaryServiceRecord:Audit'
        )
      )?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 受益人 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-project-beneficiary']}})],1),_c('audit-tab-of-internal-project-beneficiary',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-project-beneficiary', $event)}}})],1):_vm._e(),(_vm.hasAuditPermission('Pages.InternalProjectMonthlyPlan:Audit'))?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 月计划 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-monthly-plan']}})],1),_c('audit-tab-of-internal-monthly-plan',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-monthly-plan', $event)}}})],1):_vm._e(),(_vm.hasAuditPermission('Pages.InternalProjectMonthlySummarize:Audit'))?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 月总结 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-monthly-summarize']}})],1),_c('audit-tab-of-internal-monthly-summarize',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-monthly-summarize', $event)}}})],1):_vm._e(),(_vm.hasAuditPermission('Pages.InternalProjectSummaryReports:Audit'))?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 项目总结报告 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-project-summary-report']}})],1),_c('audit-tab-of-internal-project-summary-report',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-project-summary-report', $event)}}})],1):_vm._e(),(
        _vm.hasAuditPermission('Pages.InternalProjectPartnerCapitalApply:Audit')
      )?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 合作方资金申请 "),_c('audit-count',{attrs:{"value":_vm.countDic['finance-partner-capital-apply']}})],1),_c('audit-tab-of-finance-partner-capital-apply',{attrs:{"isInternal":true},on:{"count-changed":function($event){return _vm.handleCountChange('finance-partner-capital-apply', $event)}}})],1):_vm._e(),(
        _vm.hasAuditPermission(
          'Pages.InternalProjectBeneficiaryServiceRecord:Audit'
        )
      )?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 受益人服务记录 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-service-record-by-beneficiary']}})],1),_c('audit-tab-of-internal-service-record-by-beneficiary',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-service-record-by-beneficiary', $event)}}})],1):_vm._e(),(
        _vm.hasAuditPermission('Pages.InternalProjectSchoolServiceRecord:Audit')
      )?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 组织服务记录 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-service-record']}})],1),_c('audit-tab-of-internal-service-record-by-school',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-service-record', $event)}}})],1):_vm._e(),(_vm.hasAuditPermission('Pages.InternalPersonalYearEndSummary:Audit'))?_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" 个人年终总结 "),_c('audit-count',{attrs:{"value":_vm.countDic['internal-year-end-summary']}})],1),_c('tab-internal-personal-year-end-summary',{on:{"count-changed":function($event){return _vm.handleCountChange('internal-year-end-summary', $event)}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }