

































































import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfTask",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
    formatUser(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfTask extends Vue {
  importantLevel: any = []; // 紧急程度
  queryForm = {};
  userList: any[] = [];
  statusList: any[] = [];

  created() {
    this.fetchDataDictionary();
    this.fetchUserList();
    this.fetchEnumType();
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
    });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "TaskStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ImportantLevel",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.importantLevel = res.items;
      });
  }

  // 获取表数据
  get fetchData() {
    return api.userTask.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "taskManagerDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.UserTask,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
