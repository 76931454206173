var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"app-container"},[_c('div',{staticClass:"timeline-list-title"},[_vm._v("审核记录")]),(_vm.recordList && _vm.recordList.length > 0)?_c('el-timeline',{staticClass:"timeline-list",attrs:{"reverse":false}},_vm._l((_vm.recordList),function(record,index){return _c('el-timeline-item',{key:index,attrs:{"placement":"top","timestamp":_vm.getTimestamp(record)}},[_c('div',{staticClass:"margin-bottom-sm"},[(record.status == 'Ongoing')?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v("进行中")]):(record.status == 'Pass')?_c('el-tag',{attrs:{"type":"success","size":"mini"}},[_vm._v("审核通过 ")]):(record.status == 'Reject')?_c('el-tag',{attrs:{"type":"danger","size":"mini"}},[_vm._v("审核拒绝 ")]):(record.status == 'Withdraw')?_c('el-tag',{attrs:{"type":"warning","size":"mini"}},[_vm._v("审核被撤回 ")]):_vm._e()],1),_c('div',{on:{"click":function($event){return _vm.toggleDetail(record)}}},[_c('i',{class:record.show == true
              ? 'el-icon-caret-bottom'
              : 'el-icon-caret-right'}),_vm._v("审核过程明细 ")]),_c('el-collapse-transition',[(record.show == true)?_c('div',{staticClass:"audit-detail"},_vm._l((record.indexList),function(s,si){return _c('div',{key:si,staticClass:"step-wrap"},[_c('div',{staticClass:"step-row"},[_c('div',{staticClass:"step-title"},[_vm._v(" 步骤"+_vm._s(si + 1)+"/审核方式： "+_vm._s(_vm.getStepName(s, record))+" "),_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","content":record.tooltip}},[_c('i',{staticClass:"el-icon-question"})])],1),_c('el-row',{attrs:{"gutter":20}},_vm._l((record.auditNodeList.filter(
                    function (m) { return m.index == s; }
                  )),function(node,ci){return _c('el-col',{key:ci,attrs:{"md":4}},[(record.logList.length == 0)?_c('div',[_c('div',{staticClass:"audit-item wait"},[_c('div',{staticClass:"audit-status"},[_c('i',{staticClass:"el-icon-remove-outline"},[_vm._v(" 等待审核")])]),_c('div',{staticClass:"audit-user"},[_vm._v(" 审核人："+_vm._s(node.userName)+" ")]),_c('div',{staticClass:"audit-content"},[_vm._v(" 审核角色："+_vm._s(node.roleName)+" ")])])]):(
                      record.logList.filter(function (s) { return s.auditNodeId === node.id; })
                        .length > 0
                    )?_c('div',_vm._l((record.logList.filter(
                        function (s) { return s.auditNodeId === node.id; }
                      )),function(item,ui){return _c('el-popover',{key:ui,staticClass:"item",attrs:{"title":"审核明细","trigger":"hover","width":"300","effect":"dark","placement":"right","popper-class":"tips"}},[_c('div',[_c('audit-log-card-detail',{attrs:{"log":item}})],1),_c('div',{class:_vm.getClassName(item.status),attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"audit-status"},[(item.status === 'Pass')?_c('i',{staticClass:"el-icon-circle-check"},[_vm._v(" 通过")]):(item.status === 'Reject')?_c('i',{staticClass:"el-icon-circle-close"},[_vm._v(" 拒绝")]):(item.status === 'Continue')?_c('i',{staticClass:"el-icon-remove-outline"},[_vm._v(" 等待审核")]):(item.status === 'Back')?_c('i',{staticClass:"el-icon-warning-outline"},[_vm._v(" 退回")]):_vm._e()]),_c('div',{staticClass:"audit-user"},[_vm._v(" 审核人："+_vm._s(item.creatorUser.userName)+" ")]),_c('div',{staticClass:"audit-content"},[_vm._v(_vm._s(item.desc))])])])}),1):_c('div',[(
                        _vm.getAuditMethod(s, record) == 'AnyOnePassed' &&
                        record.logList.filter(function (s) { return s.index == node.index; })
                          .length > 0
                      )?_c('div',{staticClass:"audit-item info"},[_c('div',{staticClass:"audit-status"},[_c('i',{staticClass:"el-icon-remove-outline"},[_vm._v(" 自动跳过")])]),_c('div',{staticClass:"audit-user"},[_vm._v(" 审核人："+_vm._s(node.userName)+" ")]),_c('div',{staticClass:"audit-content"},[_vm._v(" 审核角色："+_vm._s(node.roleName)+" ")])]):_c('div',[(record.status == 'Ongoing')?_c('div',{staticClass:"audit-item wait"},[_c('div',{staticClass:"audit-status"},[_c('i',{staticClass:"el-icon-remove-outline"},[_vm._v(" 等待审核")])]),_c('div',{staticClass:"audit-user"},[_vm._v(" 审核人："+_vm._s(node.userName)+" ")]),_c('div',{staticClass:"audit-content"},[_vm._v(" 审核角色："+_vm._s(node.roleName)+" ")])]):_c('div',{staticClass:"audit-item info"},[_c('div',{staticClass:"audit-status"},[_c('i',{staticClass:"el-icon-remove-outline"},[_vm._v(" 自动跳过")])]),_c('div',{staticClass:"audit-user"},[_vm._v(" 审核人："+_vm._s(node.userName)+" ")]),_c('div',{staticClass:"audit-content"},[_vm._v(" 审核角色："+_vm._s(node.roleName)+" ")])])])])])}),1)],1),(si + 1 < record.indexList.length)?_c('el-divider',{attrs:{"content-position":"left"}},[_c('i',{staticClass:"el-icon-bottom"})]):_vm._e()],1)}),0):_vm._e()])],1)}),1):(_vm.isLoaded && !(_vm.recordList && _vm.recordList.length > 0))?_c('div',{staticClass:"tip"},[_c('p',[_vm._v("当前记录未发起审核流程")])]):_vm._e(),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.cancel}},[_vm._v("返回")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }