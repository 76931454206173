




































import { Component, Ref, Vue } from "vue-property-decorator";
import ProcessConfiguration from "@/views/auditFlow/components/processConfiguration/mainPage.vue";
import TriggerRules, {
  TriggerRuleDto,
} from "@/views/auditFlow/components/triggerRules/triggerRules.vue";
import api from "@/api";
import {
  AuditFlowCreateOrEditProxyDto,
  AuditFlowDefinitionDetailDto,
} from "@/api/appService";

@Component({
  name: "AuditFlowConfiguration",
  components: { TriggerRules, ProcessConfiguration },
})
export default class AuditFlowConfiguration extends Vue {
  key = "";
  auditFlowId = "";
  isSupportRules = false;
  isDefault = false;

  loading = true;
  detail: AuditFlowDefinitionDetailDto = {};

  triggerRules: TriggerRuleDto[] = [];
  triggerRuleJsonStr = "";

  async created() {
    if (this.$route.params.key) {
      this.key = this.$route.params.key;
    }
    if (this.$route.query.auditFlowId) {
      this.auditFlowId = this.$route.query.auditFlowId as string;
    }
    if (this.$route.query.isSupportRules) {
      this.isSupportRules =
        (this.$route.query.isSupportRules as string) === "true";
      await this.fetchData();
    }
  }

  async fetchData() {
    api.auditFlowDefinition.get({ key: this.key }).then((res) => {
      if (this.auditFlowId != api.guid) {
        api.auditFlowService.get({ id: this.auditFlowId }).then((res2) => {
          this.detail = res;
          if (res2 && res2.ruleConfig) {
            this.triggerRuleJsonStr = res2.ruleConfig;
          }
          this.loading = false;
        });
      } else {
        this.detail = res;
        this.loading = false;
      }
    });
  }

  triggerRulesValueChange(triggerRules: TriggerRuleDto[]) {
    this.triggerRules = triggerRules;
  }

  // 点击保存
  async save() {
    // debugger;
    // console.log('triggerRules: ' + JSON.stringify(this.triggerRules));

    let form = (
      this.$refs.process as any
    ).getForm() as AuditFlowCreateOrEditProxyDto;
    let processValid = (this.$refs.process as any).validAuditNodes(form);
    if (processValid) {
      let valid = true;
      if (this.isSupportRules) {
        valid = this.validateTriggerRules(this.triggerRules);
      }
      if (valid) {
        let routeConfig = JSON.stringify(this.triggerRules);
        form.ruleConfig = routeConfig;
        form.key = this.key;
        console.log(form);
        if (form!.id !== api.guid) {
          await api.auditFlow.update({ body: form });
        } else {
          await api.auditFlow.create({ body: form });
        }
        this.$message.success("更新成功");
      } else {
        this.$message.warning("请检查每个触发规则是否填写完全");
      }
    } else {
      this.$message.warning("请检查每个审核人员是否都配置");
    }
  }

  validateTriggerRules(triggerRules: TriggerRuleDto[]) {
    let isValid = true;
    if (triggerRules && triggerRules.length > 0) {
      triggerRules.forEach((item) => {
        if (item.triggerRuleItems && item.triggerRuleItems.length > 0) {
          item.triggerRuleItems.forEach((m) => {
            if (!m.operator || !m.value) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }
    return isValid;
  }

  goBack() {
    history.back();
  }
}
