






































import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfFundProjectModifyApply",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfFundProjectModifyApply extends Vue {
  projectType: any = [];
  typeList: any = [];

  queryForm: any = {
    name: "",
    projectType: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectType",
      })
      .then((res) => {
        this.projectType = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items;
      });
  }

  // 获取表数据
  get fetchData() {
    return api.fundProjectModifyApply.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "fundProjectModifyApplyDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundProjectModifyApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
