














































import { Component, Model, Vue, Prop, Watch } from "vue-property-decorator";
import TriggerRule from "@/views/auditFlow/components/triggerRules/triggerRule.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {
  AuditFlowConditionValueType,
  AuditFlowSupportRuleDto,
} from "@/api/appService";
import { flatenNodes } from "@/utils/tree";
import api from "@/api";

export interface TriggerRuleDto {
  key?: string;

  displayName?: string;

  valueType?: AuditFlowConditionValueType;

  valueSource: ValueSourceDto;

  triggerRuleItems: TriggerRuleItemDto[];
}

export interface ValueSourceDto {
  source?: string;

  bindValue?: string;

  bindText?: string;
}

export interface TriggerRuleItemDto {
  operator?: string;

  operatorText?: string;

  value?: any;
}

@Component({
  name: "TriggerRules",
  components: {
    TriggerRule,
    AbSelect,
  },
})
export default class TriggerRules extends Vue {
  @Model("change", { type: String, default: "" })
  readonly triggerRuleJsonStr!: string;

  @Prop({ required: true, default: () => [] })
  supportRules!: AuditFlowSupportRuleDto[];

  private triggerRules: TriggerRuleDto[] = [];
  private isEmpty = false;
  private visible = false;
  private fieldList: AuditFlowSupportRuleDto[] = [];
  private fieldKey = "";
  private displayAddButton = true;

  created() {
    this.init();
    this.setFieldList();
  }

  @Watch("fieldList", { immediate: true, deep: true })
  fieldListValueChange() {
    if (this.fieldList && this.fieldList.length > 0) {
      this.displayAddButton = true;
    } else {
      this.displayAddButton = false;
    }
  }

  @Watch("triggerRules", { immediate: true, deep: true })
  triggerRulesValueChange() {
    this.$emit("triggerRulesValueChange", this.triggerRules);
  }

  init() {
    if (this.triggerRuleJsonStr) {
      console.log("triggerRuleJsonStr: " + this.triggerRuleJsonStr);
      this.triggerRules = JSON.parse(this.triggerRuleJsonStr);
      if (this.triggerRules && this.triggerRules.length > 0) {
        this.isEmpty = false;
      }
    } else {
      // this.triggerRules = [{
      //   key: "",
      //   triggerRuleItems: [{
      //     operator: '',
      //     operatorText: '',
      //     value: ''
      //   }],
      //   valueType: undefined,
      //   displayName: '',
      //   valueSource: {
      //     source: '',
      //     bindValue: '',
      //     bindText: ''
      //   }
      // }];
      this.triggerRules = [];
      this.isEmpty = true;
    }
  }

  setFieldList() {
    //获取fieldList api
    this.fieldList = [...this.supportRules];

    //将存在于triggerRules中的key，从fieldList中去除，即不显示在下拉框中
    if (this.triggerRules && this.triggerRules.length > 0) {
      this.fieldList = [
        ...this.fieldList.filter((s) =>
          this.triggerRules.every((t) => t.key !== s.key)
        ),
      ];
    }
  }

  removeTriggerRule(index: any) {
    let removeRule = this.triggerRules[index];
    this.triggerRules!.splice(index, 1);
    console.log(
      "remove trigger rule -- trigger rules: " +
        JSON.stringify(this.triggerRules)
    );
    if (this.fieldList.filter((m) => m.key === removeRule.key).length == 0) {
      this.fieldList.push(
        this.supportRules.filter((m) => m.key === removeRule.key)[0]
      );
    }
    if (this.triggerRules!.length == 0) {
      this.isEmpty = true;
    }
  }

  addTriggerRule() {
    this.visible = false;
    let chooseField = this.fieldList.filter((m) => m.key == this.fieldKey)[0];
    this.triggerRules!.push({
      key: this.fieldKey,
      valueType: chooseField.valueType,
      displayName: chooseField.displayName,
      triggerRuleItems: [],
      valueSource: chooseField.valueSource!,
    });
    let fieldIndex = this.fieldList.indexOf(chooseField);
    if (fieldIndex > -1) {
      this.fieldList.splice(fieldIndex, 1);
    }
    console.log("supportRules: " + JSON.stringify(this.supportRules));
    this.fieldKey = "";
    this.isEmpty = false;
  }

  cancel() {
    this.visible = false;
    this.fieldKey = "";
  }

  triggerRuleValueChange(index: number, triggerRuleDto: TriggerRuleDto) {
    this.triggerRules[index] = triggerRuleDto;
  }
}
