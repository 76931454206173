































import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfActivityApply from "@/views/myAudit/tabs/tabActivityApply.vue";
import AuditTabOfActivityRecord from "@/views/myAudit/tabs/tabActivityRecord.vue";
import AuditTabOfActivityApplyCheckIn from "@/views/myAudit/tabs/tabActivityApplyCheckIn.vue";

@Component({
  name: "AuditTabActivity",
  components: {
    AuditTabOfActivityApplyCheckIn,
    AuditCount,
    AuditTabOfActivityApply,
    AuditTabOfActivityRecord,
  },
})
export default class AuditTabActivity extends Vue {
  countDic: any = {
    "activity": 0,
    "activity-record":0,
    "activity-apply-checkIn":0
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["activity", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
