



















import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
// import {AuditUserLogType} from "@/api/appService";

@Component({
  name: "AuditTabOfInternalMonthlySummarize",
  components: { AuditTabPane },
})
export default class AuditTabOfInternalMonthlySummarize extends Vue {
  get fetchData() {
    return api.internalMonthlySummarize.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "internalMonthlySummarizeDetail",
      params: {
        id: dto.id,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
