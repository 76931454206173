








































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Provide, Ref } from "vue-property-decorator";
import { MessageBox } from "element-ui";

import { AuditFlowDto } from "@/api/appService";

import EditAuditFlow from "./edit-auditFlow.vue";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "AuditFlowList",
  components: {
    PagedTableView,
    EditAuditFlow,
  },
})
export default class AuditFlowList extends Vue {
  queryForm = {
    desc: "",
  };

  // 获取表数据
  async fetchData(params: any) {
    return api.auditFlow.getAll(params);
  }
  handleCreate() {
    this.$router.push({ name: "auditFlowDefinitionCreate" });
  }

  handleEdit(row: AuditFlowDto) {
    this.$router.push({
      name: "auditFlowDefinitionEdit",
      params: { id: row.id! },
    });
  }

  // 删除
  async handleDelete(index: number, row: AuditFlowDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.auditFlow
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }
}
