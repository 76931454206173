























import { Component, Vue, Prop } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
// import {AuditUserLogType} from "@/api/appService";

@Component({
  name: "AuditTabOfFinancePartnerCapitalApply",
  components: { AuditTabPane },
})
export default class AuditTabOfFinancePartnerCapitalApply extends Vue {
  get fetchData() {
    return api.partnerCapitalApply.getMyAll;
  }
  @Prop({ required: true, default: false })
  isInternal!: boolean;

  handleAudit(dto: any) {
    return this.$router.push({
      name: "partnerCapitalApplyDetail",
      params: { id: dto.id!.toString() },
    });
  }

  get queryParams() {
    return {
      isInternal: this.isInternal,
    };
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
