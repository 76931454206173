












































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Ref, Vue } from "vue-property-decorator";
import RejectDialog from "@/components/RejectDialog/index.vue";

import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {} from "@/api/appService";
import AuditTabOfInternalMonthlySummarize from "@/views/myAudit/tabs/tabInternalMonthlySummarize.vue";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfExternalDueDiligenceRecord from "@/views/myAudit/tabs/tabExternalDueDiligenceRecord.vue";
import AuditTabOfExternalMonthlyPlan from "@/views/myAudit/tabs/tabExternalMonthlyPlan.vue";
import AuditTabOfExternalMonthlySummarize from "@/views/myAudit/tabs/tabExternalMonthlySummarize.vue";
import AuditTabOfExternalProject from "@/views/myAudit/tabs/tabExternalProject.vue";
import AuditTabOfExternalProjectCloseReport from "@/views/myAudit/tabs/tabExternalProjectCloseReport.vue";
import AuditTabOfExternalProjectIntention from "@/views/myAudit/tabs/tabExternalProjectIntention.vue";
import AuditTabOfExternalProjectProcessMonitoring from "@/views/myAudit/tabs/tabExternalProjectProcessMonitoring.vue";
import AuditTabOfExternalProjectProgressReport from "@/views/myAudit/tabs/tabExternalProjectProgressReport.vue";
import AuditTabOfExternalProjectSummaryReport from "@/views/myAudit/tabs/tabExternalProjectSummaryReport.vue";
import AuditTabOfFinancePartnerCapitalApply from "@/views/myAudit/tabs/tabFinancePartnerCapitalApply.vue";
import TabExternalProjectProposalChangeApply from "@/views/myAudit/tabs/tabExternalProjectProposalChangeApply.vue";

@Component({
  name: "AuditTabGroupOfExternalProject",
  components: {
    TabExternalProjectProposalChangeApply,
    AuditTabOfExternalProjectSummaryReport,
    AuditTabOfExternalProjectProgressReport,
    AuditTabOfExternalProjectProcessMonitoring,
    AuditTabOfExternalProjectIntention,
    AuditTabOfExternalProjectCloseReport,
    AuditTabOfExternalProject,
    AuditTabOfExternalMonthlySummarize,
    AuditTabOfExternalMonthlyPlan,
    AuditTabOfExternalDueDiligenceRecord,
    AuditCount,
    AuditTabOfFinancePartnerCapitalApply,
  },
})
export default class AuditTabGroupOfExternalProject extends Vue {
  countDic: any = {
    "external-due-diligence-record": 0,
    "external-monthly-plan": 0,
    "external-monthly-summarize": 0,
    "external-project": 0,
    "external-project-close-report": 0,
    "external-project-intention": 0,
    "external-project-process-monitoring": 0,
    "external-project-progress-report": 0,
    "external-project-summary-report": 0,
    "finance-partner-capital-apply": 0,
    "external-project-proposal-change-apply": 0,
  };

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
