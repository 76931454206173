




























































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import api from "@/api";
import {
  AuditNodePreDefineCreateOrEditDto,
  UserDto,
  RoleDto,
} from "@/api/appService";
import UserSelect from "@/components/UserSelect/index.vue";

@Component({
  components: { UserSelect },
})
export default class EditAuditNodePreDefine extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly userSelect!: UserSelect;

  get dialogTitle() {
    return this.form!.id !== api.guid ? "编辑" : "新建";
  }

  async created() {
    await api.role
      .getAll({
        maxResultCount: 100,
      })
      .then((res) => {
        this.roles = res.items!;
      });
  }

  roles: RoleDto[] = [];

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      if (this.form!.id) {
        console.log(this.form, "form");
        await api.auditNodePreDefine.get({ id: this.form!.id }).then((res) => {
          this.form = res;
        });
      }
    } else {
      this.form = { ...this.defaultData };
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  defaultData: AuditNodePreDefineCreateOrEditDto = {
    desc: undefined,
    roleName: undefined,
    roleId: undefined,
    userName: undefined,
    userId: undefined,
    organizationUnitId: undefined,
    id: undefined,
  };

  show = false;
  form: AuditNodePreDefineCreateOrEditDto = { ...this.defaultData };

  async selectRole(e: number) {
    if (e) {
      this.form!.roleName = this.roles.find((x) => x.id == e)!.displayName;
      this.form!.roleId = this.roles.find((x) => x.id == e)!.id;
    } else {
      this.form!.roleName = undefined;
      this.form!.roleId = undefined;
    }
  }

  async save() {
    console.log(this.form);

    if (!this.form.userId && !this.form.roleId) {
      this.$message.error("审核用户或权限必须选择其中一项");
      return;
    }
    console.log(this.form);
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        console.log(api.guid, "api.guid");
        if (this.form!.id) {
          await api.auditNodePreDefine.update({ body: this.form });
        } else {
          await api.auditNodePreDefine.create({ body: this.form });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("save");
      }
    });
  }

  async openUserSelect() {
    (this.userSelect as any).show = true;
  }
  async onSelect(user: UserDto) {
    console.log(user);
    this.form!.userName = user.name;
    this.form!.userId = user.id;
  }

  cancel() {
    this.show = false;
  }

  clearUser() {
    this.form!.userName = undefined;
    this.form!.userId = undefined;
  }

  roleRule = {
    desc: [
      {
        required: true,
        message: "必填",
        trigger: "blur",
      },
    ],
  };
}
