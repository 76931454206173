import { render, staticRenderFns } from "./tabExternalDueDiligenceRecord.vue?vue&type=template&id=3572af91&scoped=true&"
import script from "./tabExternalDueDiligenceRecord.vue?vue&type=script&lang=ts&"
export * from "./tabExternalDueDiligenceRecord.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3572af91",
  null
  
)

export default component.exports