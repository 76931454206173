

























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {AuditFlowScope, CustomFormType} from "@/api/appService";

@Component({ name: "AuditTabOfOrganizationUnit", components: { AuditTabPane } })
export default class AuditTabOfOrganizationUnit extends Vue {
  queryForm = {
    isApply: true,
  };
  private formId = "";

  // created(){
  //   api.customFormService.getCustomFormId({hostId:'',hostType:CustomFormType.OrganizationUnit}).then(res=>{
  //     this.formId = res;
  //   })
  // }

  get fetchData() {
    return api.organizationUnit.getMyAll;
  }

  handleAudit(dto: any) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.OrganizationUnit,
      })
      .then((res) => {
        this.formId = res;

        return this.$router.push({
          name: "orgDetail",
          query: {
            id: dto.id!.toString(),
            formId: this.formId,
          },
        });
      });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.PartnerApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }


}
