
























import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfFundApply from "@/views/myAudit/tabs/tabFundApply.vue";
import AuditTabOfFundModifyApply from "@/views/myAudit/tabs/tabFundModifyApply.vue";

@Component({
  name: "AuditTabFund",
  components: {
    AuditCount,
    AuditTabOfFundApply,
    AuditTabOfFundModifyApply,
  },
})
export default class AuditTabFund extends Vue {
  countDic: any = {
    "fund-apply": 0,
    "fund-modify-apply": 0,
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["fund", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
