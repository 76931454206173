































import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfHelpApply from "@/views/myAudit/tabs/tabHelpApply.vue";
import AuditTabOfServiceRecord from "@/views/myAudit/tabs/tabServiceRecord.vue";
import AuditTabOfServiceApplyCheckIn from "@/views/myAudit/tabs/tabServiceApply.vue";

@Component({
  name: "AuditTabService",
  components: {
    AuditTabOfServiceApplyCheckIn,
    AuditTabOfServiceRecord,
    AuditTabOfHelpApply,
    AuditCount,
  },
})
export default class AuditTabService extends Vue {
  countDic: any = {
    "helpApply":0,
    "serviceRecord":0,
    "serviceApply":0
  };
  totalCount = 0;
  activeName="服务需求管理"

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["service", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }

  handleTabChange(e:any){
    console.log("handleTabChange",e.name)
    this.$emit("tab2-name", e.name);
  }
}
