































import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfVolunteer from "@/views/myAudit/tabs/tabVolunteer.vue";
import AuditTabOfStarLevelEvaluate from "@/views/myAudit/tabs/tabStarLevelEvaluate.vue";
import AuditTabOfCmsContent from "@/views/myAudit/tabs/tabCmsContent.vue";

@Component({
  name: "AuditTabVolunteer",
  components: {
    AuditTabOfCmsContent,
    AuditTabOfStarLevelEvaluate,
    AuditCount,
    AuditTabOfVolunteer
  }
})
export default class AuditTabTask extends Vue {
  countDic: any = {
    volunteer: 0,
    starRatingRecord: 0,
    cmsContent: 0
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["volunteer", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
