















import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfTask from "@/views/myAudit/tabs/tabTask.vue";

@Component({
  name: "AuditTabTask",
  components: {
    AuditCount,
    AuditTabOfTask,
  },
})
export default class AuditTabTask extends Vue {
  countDic: any = {
    task: 0,
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["task", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
