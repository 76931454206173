




































import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundModifyApplyDto,
} from "@/api/appService";

@Component({
  name: "AuditTabOfFundModifyApply",
  components: { AuditTabPane },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
    formatApplyStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfFundModifyApply extends Vue {
  typeList: any = [];
  statusList: any = [];
  queryForm = {
    name: "",
    type: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApplyStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  // 获取表数据
  get fetchData() {
    return api.fundModifyApply.getMyAll;
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundModifyApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "fundModifyApplyDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundModifyApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
