






























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  HelpAppliesDto,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfHelpApply",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfHelpApply extends Vue {
  tableItems: HelpAppliesDto[] = [];
  typeList: any = [];

  queryForm: any = {
    name: "",
  };

  // 获取表数据
  get fetchData() {
    return api.helpApplies.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "helpAppliesDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.HelpApply,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
