














import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
// import {AuditUserLogType} from "@/api/appService";

@Component({
  name: "tabInternalPersonalYearEndSummary",
  components: { AuditTabPane },
})
export default class tabInternalPersonalYearEndSummary extends Vue {
  get fetchData() {
    return api.internalPersonalYearEndSummary.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "internalPersonalYearEndSummaryDetail",
      params: {
        id: dto.id,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
