






































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
  ProvideReactive,
} from "vue-property-decorator";

import api from "@/api";

import {
  AuditFlowCreateOrEditDto,
  AuditNodeCreateOrEditDto,
  AuditFlowScope,
  RoleDto,
  AuditFlowType,
  AuditFlowCreateOrEditProxyDtoGetForEditOutput,
  AuditFlowCreateOrEditProxyDto,
  AuditFLowStatus,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

import draggable from "vuedraggable";

interface ScopeMapping {
  name: AuditFlowScope;
  label: string;
}

@Component({
  components: {
    draggable,
  },
})
export default class EditAuditFlow extends Vue {
  @Prop({ required: true })
  item: any;

  @Prop({ required: true })
  index!: number;

  @Prop({ required: true })
  roles!: RoleDto[];

  @Prop({ required: true })
  users!: any[];

  drag = false;


  newUsers:any[]=[];

  created() {
    api.user.getAll({roleId:41,mode:'foundation'}).then((res)=>{
      this.newUsers=res.items!;
    })
  }

  // 选权限下拉结束
  private selectRole(value: number, item: AuditNodeCreateOrEditDto) {
    if (!value) {
      item.roleName = undefined;
      item.roleId = undefined;
    } else {

      item.userName=undefined
      item.userId=undefined
      item.roleName = this.roles.filter((x) => x.id === value)[0].displayName;
      item.roleId = this.roles.filter((x) => x.id === value)[0].id;
      api.user.getAll({roleId:item.roleId,mode:'foundation'}).then((res)=>{
        this.newUsers=res.items!;
      })
    }
  }

  // 选验证用户下拉结束
  private selectUser(value: number, item: AuditNodeCreateOrEditDto) {
    console.log(value, item);
    if (!value) {
      item.userName = undefined;
      item.userId = undefined;
    } else {
      console.log(this.newUsers.filter((x) => x.id === value)[0])
      item.userName = this.newUsers.filter((x) => x.id === value)[0].name;
      item.userId = this.newUsers.filter((x) => x.id === value)[0].id;
    }
  }
}
