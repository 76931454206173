










import {Component, Prop, Vue} from "vue-property-decorator";
import {AttachmentHostType, LogDto, UserAuditLogDto} from "@/api/appService";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name:'AuditLogCardDetail',
  components: {AttachmentsView}
})
export default class AuditLogCardDetail extends Vue {

  @Prop({required:true})
  log!:LogDto;

  get hostType(){
    return AttachmentHostType.Audit
  }



}

