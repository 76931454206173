





























































import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfFundProjectApply from "@/views/myAudit/tabs/tabFundProjectApply.vue";
import AuditTabOfFundProjectModifyApply from "@/views/myAudit/tabs/tabFundProjectModifyApply.vue";
import AuditTabOfFundAppropriateApply from "@/views/myAudit/tabs/tabFundAppropriateApply.vue";
import AuditTabOfFundNonCashDonation from "@/views/myAudit/tabs/tabFundNonCashDonation.vue";
import AuditTabOfApplicationPayment from "@/views/myAudit/tabs/tabApplicationPayment.vue";

@Component({
  name: "AuditTabFundProject",
  components: {
    AuditCount,
    AuditTabOfFundProjectApply,
    AuditTabOfFundProjectModifyApply,
    AuditTabOfFundAppropriateApply,
    AuditTabOfFundNonCashDonation,
    AuditTabOfApplicationPayment,
  },
})
export default class AuditTabFundProject extends Vue {
  countDic: any = {
    "fund-project-apply": 0,
    "fund-project-modify-apply": 0,
    "fund-appropriate-apply": 0,
    "fund-non-cash-donation": 0,
    "application-payment": 0,
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["fundProject", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
