import { AuditFlowConditionValueType } from "./src/api/appService";

export interface ITriggerRuleOperatorSettings {
  key: string;
  displayName: string;
  type: AuditFlowConditionValueType;
  // 是否对其他所有运算符互斥，若是，则mutex=[]
  isMutex: boolean;
  // 当前运算符的互斥项的key
  mutex: string[];
}

const triggerRuleOperatorSettings: ITriggerRuleOperatorSettings[] = [
  {
    key: "==",
    displayName: "==",
    type: AuditFlowConditionValueType.Number,
    isMutex: true,
    mutex: [],
  },
  {
    key: "!=",
    displayName: "!=",
    type: AuditFlowConditionValueType.Number,
    isMutex: true,
    mutex: [],
  },
  {
    key: ">",
    displayName: ">",
    type: AuditFlowConditionValueType.Number,
    isMutex: false,
    mutex: [">", ">="],
  },
  {
    key: ">=",
    displayName: ">=",
    type: AuditFlowConditionValueType.Number,
    isMutex: false,
    mutex: [">=", ">"],
  },
  {
    key: "<",
    displayName: "<",
    type: AuditFlowConditionValueType.Number,
    isMutex: false,
    mutex: ["<", "<="],
  },
  {
    key: "<=",
    displayName: "<=",
    type: AuditFlowConditionValueType.Number,
    isMutex: false,
    mutex: ["<=", "<"],
  },
  {
    key: "==",
    displayName: "等于",
    type: AuditFlowConditionValueType.DataSource,
    isMutex: true,
    mutex: [],
  },
  {
    key: "!=",
    displayName: "不等于",
    type: AuditFlowConditionValueType.DataSource,
    isMutex: true,
    mutex: [],
  },
  {
    key: "in",
    displayName: "包含",
    type: AuditFlowConditionValueType.DataSource,
    isMutex: true,
    mutex: [],
  },
  {
    key: "notin",
    displayName: "不包含",
    type: AuditFlowConditionValueType.DataSource,
    isMutex: true,
    mutex: [],
  },
  {
    key: "==",
    displayName: "等于",
    type: AuditFlowConditionValueType.Enum,
    isMutex: true,
    mutex: [],
  },
  {
    key: "!=",
    displayName: "不等于",
    type: AuditFlowConditionValueType.Enum,
    isMutex: true,
    mutex: [],
  },
  {
    key: "in",
    displayName: "包含",
    type: AuditFlowConditionValueType.Enum,
    isMutex: true,
    mutex: [],
  },
  {
    key: "notin",
    displayName: "不包含",
    type: AuditFlowConditionValueType.Enum,
    isMutex: true,
    mutex: [],
  },
  {
    key: "==",
    displayName: "等于",
    type: AuditFlowConditionValueType.String,
    isMutex: true,
    mutex: [],
  },
  {
    key: "!=",
    displayName: "不等于",
    type: AuditFlowConditionValueType.String,
    isMutex: true,
    mutex: [],
  },
  {
    key: "contains",
    displayName: "包含",
    type: AuditFlowConditionValueType.String,
    isMutex: true,
    mutex: [],
  },
  {
    key: "not-contains",
    displayName: "不包含",
    type: AuditFlowConditionValueType.String,
    isMutex: true,
    mutex: [],
  },
];

export function getOperatorsByType(type?: AuditFlowConditionValueType) {
  if (type) {
    return [...triggerRuleOperatorSettings.filter((m) => m.type === type)];
  } else {
    return [...triggerRuleOperatorSettings];
  }
}

export function getOperatorByKey(
  key: string,
  type: AuditFlowConditionValueType
) {
  return [
    ...triggerRuleOperatorSettings.filter(
      (m) => m.key === key && m.type === type
    ),
  ][0];
}

export function getOperatorsIsMutex(type?: AuditFlowConditionValueType) {
  if (type) {
    return [
      ...triggerRuleOperatorSettings.filter(
        (m) => m.isMutex && m.type === type
      ),
    ];
  } else {
    return [...triggerRuleOperatorSettings.filter((m) => m.isMutex)];
  }
}

export function getMutexByKey(
  key: string,
  type: AuditFlowConditionValueType,
  onlySelfMutex?: boolean
) {
  const operator = getOperatorByKey(key, type);
  if (operator.isMutex) {
    return getOperatorsByType(type);
  } else {
    const mutex: ITriggerRuleOperatorSettings[] = [];
    if (operator.mutex && operator.mutex.length > 0) {
      operator.mutex.forEach((m) => {
        mutex.push(getOperatorByKey(m, type));
      });
    }
    if (onlySelfMutex) {
      return [...mutex];
    } else {
      return [...getOperatorsIsMutex(type), ...mutex];
    }
  }
}

export function differ(
  source: ITriggerRuleOperatorSettings[],
  target: ITriggerRuleOperatorSettings[]
) {
  return [...source.filter((s) => target.every((t) => t.key !== s.key))];
}

export function add(
  source: ITriggerRuleOperatorSettings[],
  target: ITriggerRuleOperatorSettings[]
) {
  return [...source, ...target];
}
