



















































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import api from "@/api";

import {
  AuditFlowCreateOrEditProxyDto,
  AuditFlowScope,
  AuditFlowType,
  AuditMethod,
  AuditNodeCreateOrEditDto,
  RoleDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

import { createNodes, flatenNodes, INodesBlock } from "@/utils/tree";
import StepRow from "@/views/auditFlow/components/processConfiguration/stepRow.vue";

import draggable from "vuedraggable";

interface ScopeMapping {
  name: AuditFlowScope;
  label: string;
}

@Component({
  components: {
    draggable,
    StepRow,
  },
})
export default class EditAuditFlow extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: false })
  auditFlowId?: string;

  roles: RoleDto[] = [];
  allScopes: ScopeMapping[] = [];

  async created() {
    this.fetchAllScopes();

    const p1 = api.role.getAll({
      maxResultCount: 100,
    });
    const p2 = api.auditNodePreDefine.getAll();

    const p3 = api.user.getAllUsers();

    await Promise.all([p1, p2, p3]).then((values) => {
      this.roles = values[0].items!;

      for (let index = 0; index <= 99; index++) {
        this.predefined.push({
          desc: "",
          userId: undefined,
          userName: undefined,
          roleId: undefined,
          roleName: undefined,
          auditFlowId: undefined,
          tenantId: undefined,
        });
      }

      // this.predefined = values[1].items!.map(x =>
      //   Object.assign({}, x, {id: api.guid})
      // );
      this.users = values[2]!.map((x) => {
        return { label: x.name, value: x.id };
      });
    });

    this.fetchReadyData();
  }

  users: any[] = [{ label: "admin", value: 2 }];

  fetchAllScopes() {
    api.enumService
      .getValues({ typeName: "AuditFlowScope" })
      .then((res: any) => {
        this.allScopes = res;
      });
  }

  getAuditFlowType(value: string) {
    if (value === "AuditAll") {
      return AuditFlowType.AuditAll;
    } else if (value === "AuditOne") {
      return AuditFlowType.AuditOne;
    }
  }

  fetchReadyData() {
    api.auditFlow
      .getForEdit({ id: this.auditFlowId ? this.auditFlowId : api.guid })
      .then((res) => {
        this.form = res.data;
        this.rows = createNodes(res.data!.auditNodes!);
        this.isLoaded = true;
      });
  }

  // 移除空行
  private removeEmptyRow() {
    this.rows = this.rows.filter((x) => x.items.length > 0);
  }

  // 添加一行
  private addRow(r: number) {
    let index = this.rows.length;
    let items =
      r > 0
        ? [
            {
              desc: `审核节点${index + 1}`,
              userId: undefined,
              userName: undefined,
              roleId: undefined,
              roleName: undefined,
              auditFlowId: undefined,
              tenantId: undefined,
              auditMethod: AuditMethod.AnyOnePassed,
            } as AuditNodeCreateOrEditDto,
          ]
        : [];
    this.rows = [
      ...this.rows,
      {
        items: items,
      },
    ];
  }

  drag = false;

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: "ghost",
    };
  }

  // 删除整行
  private deleteRow(index: number) {
    this.rows.splice(index, 1);
  }

  // 删除空节点
  private removeEmptyNode(index: number) {
    const result: INodesBlock[] = [];

    this.rows.forEach((r) => {
      result.push({
        items: r.items.filter((x) => {
          return !!x.userId || !!x.roleId;
        }),
      });
    });
    this.rows = result;
    this.removeEmptyRow();
  }

  // 选权限下拉结束
  private selectRole(value: number, item: AuditNodeCreateOrEditDto) {
    if (!value) {
      item.roleName = undefined;
      item.roleId = undefined;
    } else {
      item.roleName = this.roles.filter((x) => x.id === value)[0].displayName;
      item.roleId = this.roles.filter((x) => x.id === value)[0].id;
    }
  }

  // 选验证用户下拉结束
  private selectUser(value: number, item: AuditNodeCreateOrEditDto) {
    console.log(value, item);
    if (!value) {
      item.userName = undefined;
      item.userId = undefined;
    } else {
      item.userName = this.users.filter((x) => x.value === value)[0].label;
      item.userId = this.users.filter((x) => x.value === value)[0].value;
    }
  }

  // 块Drag结束
  private async dragEnd1(e: any) {
    console.log(e);
  }

  // Node Drag 结束
  private async dragEnd2(e: any) {
    console.log("dragEnd2");
  }

  // predefined Drag 结束
  private async dragEnd3(e: any) {
    console.log(this.rows);
  }

  private async dragStart3(e: any) {
    console.log(e);
  }

  recycle: any[] = [];

  private async dragEnd4(e: any) {
    this.recycle = [];
  }

  predefined: AuditNodeCreateOrEditDto[] = [];

  enabled = true;
  isLoaded = false;
  rows: INodesBlock[] = [
    {
      items: [
        {
          desc: "审核节点1",
          roleName: undefined,
          roleId: undefined,
          userName: undefined,
          userId: undefined,
          auditMethod: AuditMethod.AnyOnePassed,
        },
      ],
    },
  ];

  // 点击保存
  async save() {
    this.form!.auditNodes = flatenNodes(this.rows);
    console.log(this.form);
    let valid = this.validAuditNodes(this.form);
    if (valid) {
      // if (this.form!.id !== api.guid) {
      //   await api.auditFlow.update({body: this.form});
      // } else {
      //   await api.auditFlow.create({body: this.form});
      // }
      // this.$message.success("更新成功");
      // await this.$router.push({name: "auditFlowDefinitionManagement"});
    } else {
      this.$message.warning("请检查每个审核人员是否都配置");
    }
    // this.dataForm.validate(async (valid: boolean) => {
    //   if (valid) {
    //     if (this.form!.id !== api.guid) {
    //       await api.auditFlow.update({body: this.form});
    //     } else {
    //       await api.auditFlow.create({body: this.form});
    //     }
    //     this.$message.success("更新成功");
    //     await this.$router.push({name: "auditFlowDefinitionManagement"});
    //   }
    // });
  }

  getForm() {
    this.form!.auditNodes = flatenNodes(this.rows);
    return this.form;
  }

  validAuditNodes(form: any) {
    let isValid = true;
    if (form && form.auditNodes && form.auditNodes.length > 0) {
      form.auditNodes.forEach((item: any) => {
        if (!item.roleId && !item.userId) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }
    return isValid;
  }

  // 表单默认值
  defaultData: AuditFlowCreateOrEditProxyDto = {
    title: undefined,
    status: undefined,
    nodesMaxIndex: undefined,
    desc: undefined,
    type: undefined,
    auditNodes: undefined,
    id: undefined,
    scope: undefined,
  };

  form: AuditFlowCreateOrEditProxyDto | undefined = { ...this.defaultData };

  cancel() {
    this.$router.back();
  }

  // 验证开始
  private validNodes = (a: any, b: any, c: any) => {
    console.log(a, b, c);
    return c(new Error("sdf"));
    // return c();
  };

  roleRule = {
    title: [
      {
        required: true,
        message: "必填",
        trigger: "blur",
      },
    ],
    auditNodes: [
      {
        validate: this.validNodes,
        trigger: "blur",
      },
    ],
    scope: [
      {
        required: true,
        trigger: "blur",
      },
    ],
  };
}
