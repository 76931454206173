




































import { Component, Ref, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  AuditFlowScope,
  UserAuditInput,
  VolunteerStatus,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import PagedTableView from "@/components/PagedTableView/index.vue";
import RejectDialog from "@/components/RejectDialog/index.vue";
import {FetchAuditItems} from "@/views/myAudit/components/AuditTabPane.vue";

@Component({
  name: "AuditTabOfVolunteer",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
    formatUser(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfVolunteer extends Vue {
  @Ref() tableView!: PagedTableView;
  @Ref() backDialog!: RejectDialog;
  @Ref() backDialogBatch!: RejectDialog;
  queryForm = {
    volunteerName: "",
    volunteerPhone: "",
    volunteerCode: "",
    status: "",
  };

  id = 0;

  statusList: any[] = [];

  created() {
    api.enumService
      .getValues({ typeName: "VolunteerStatus" })
      .then((res: any) => {
        this.statusList = res;
      });
  }

  get VolunteerStatus() {
    return VolunteerStatus;
  }

  selection: UserAuditInput[] = [];

  // 获取表数据
  get fetchData():FetchAuditItems {
    return api.volunteer.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "VolunteerAuditDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.UserTask,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
