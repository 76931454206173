



























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
// import {AuditUserLogType} from "@/api/appService";

@Component({ name: "AuditTabOfBeneficiary", components: { AuditTabPane } })
export default class AuditTabOfBeneficiary extends Vue {
  get fetchData() {
    return api.beneficiary.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "",
      params: {},
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
