































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AbSelect from "@/components/AbSelect/index.vue";
import {
  TriggerRuleItemDto,
  ValueSourceDto,
} from "@/views/auditFlow/components/triggerRules/triggerRules.vue";
import { AuditFlowConditionValueType } from "@/api/appService";
import {
  getOperatorsByType,
  ITriggerRuleOperatorSettings,
} from "../../../../../triggerRuleOperatorSettings";
import api from "@/api";
import axios from "axios";

@Component({
  name: "TriggerRuleItem",
  components: {
    AbSelect,
  },
})
export default class TriggerRuleItem extends Vue {
  @Prop({ required: true, default: AuditFlowConditionValueType.Number })
  type!: AuditFlowConditionValueType;

  @Prop({ required: true, default: 0 })
  index!: number;

  @Prop({ required: false })
  valueSource?: ValueSourceDto;

  @Prop({ required: true, default: () => ({ operator: "", value: "" }) })
  triggerRuleItem!: TriggerRuleItemDto;

  @Prop({ required: false })
  canChooseOperatorList?: ITriggerRuleOperatorSettings[];

  private operatorList: ITriggerRuleOperatorSettings[] = [];
  private operator = "";
  private operatorText = "";
  private valueList: any[] = [];
  private value: any = "";

  private isMultiple = false;

  async created() {
    this.loadOperatorList();
    await this.getValueList();
    if (this.triggerRuleItem) {
      this.operator = this.triggerRuleItem.operator!;
      this.operatorText = this.triggerRuleItem.operatorText!;
      this.value = this.triggerRuleItem.value!;
    }
    // this.setValueType(this.operator);
  }

  @Watch("canChooseOperatorList", { immediate: true, deep: true })
  canChooseOperatorListValueChange() {
    this.operatorList = [];
    for (let i = 0; i < (this as any).canChooseOperatorList!.length; i++) {
      this.operatorList.push((this as any).canChooseOperatorList[i]!);
    }
  }

  @Watch("triggerRuleItem", { immediate: true, deep: true })
  triggerRuleItemValueChange() {
    if (this.triggerRuleItem) {
      // this.loadOperatorList();
      this.operator = this.triggerRuleItem.operator!;
      this.operatorText = this.triggerRuleItem.operatorText!;
      this.value = this.triggerRuleItem.value!;
    }
  }

  loadOperatorList() {
    if (this.canChooseOperatorList && this.canChooseOperatorList.length > 0) {
      this.operatorList = this.canChooseOperatorList;
    } else {
      this.operatorList = getOperatorsByType(this.type);
    }
  }

  async operatorChange(key: string) {
    this.setValueType(key);
    this.$emit(
      "operatorChange",
      this.index,
      key,
      this.operatorList.filter((m) => m.key == key)[0].displayName,
      this.type
    );
  }

  valueChange(value: string) {
    this.$emit("valueChange", this.index, value);
  }

  removeTriggerRuleItem() {
    this.$emit("removeTriggerRuleItem", this.index, this.type);
  }

  setValueType(key: string) {
    this.isMultiple =
      (this.type === AuditFlowConditionValueType.Enum ||
        this.type === AuditFlowConditionValueType.DataSource) &&
      (key === "in" || key === "notin");
  }

  async getValueList() {
    switch (this.type) {
      case AuditFlowConditionValueType.Enum:
        await this.getEnumValueList();
        break;
      case AuditFlowConditionValueType.DataSource:
        await this.getDataSourceValueList();
        break;
    }
  }

  async getEnumValueList() {
    await api.enumService
      .getValues({ typeName: this.valueSource?.source })
      .then((res) => {
        this.valueList = res;
        console.log("enum valueList: " + JSON.stringify(this.valueList));

        if (this.valueSource && !this.valueSource.bindText) {
          this.valueSource.bindText = "text";
        }
        console.log("enum bind text: " + this.valueSource?.bindText);

        if (this.valueSource && !this.valueSource.bindValue) {
          this.valueSource.bindValue = "value";
        }
        console.log("enum bind value: " + this.valueSource?.bindValue);
      });
  }

  async getDataSourceValueList() {
    if (!this.valueSource || !this.valueSource.source) {
      return;
    }

    await axios
      .get(this.valueSource.source, {
        method: "get",
        baseURL: process.env.VUE_APP_BASE_API,
      })
      .then((res) => {
        this.valueList = res.data.result;
        console.log("data source valueList: " + JSON.stringify(this.valueList));

        if (this.valueSource && !this.valueSource.bindText) {
          this.valueSource.bindText = "displayName";
        }
        console.log("data source bind text: " + this.valueSource?.bindText);

        if (this.valueSource && !this.valueSource.bindValue) {
          this.valueSource.bindValue = "id";
        }
        console.log("data source bind value: " + this.valueSource?.bindValue);
      });
  }
}
