











































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {
  AuditFlowDto,
  AuditFlowScope,
  AuditMethod,
  AuditFlowType,
  AuditNodeDto,
  AuditUserLogDto,
  AuditUserLogStatus
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AuditLogCardDetail from "@/views/audits/components/AuditLogCardDetail.vue";

@Component({
  name: "AuditFlowDetail",
  components: {AuditLogCardDetail}
})
export default class AuditFlowDetail extends Vue {
  private hostId?: string;
  private hostType?: AuditFlowScope;
  private auditFlowId?: string;
  private auditFlow?: AuditFlowDto;
  private recordList: any[] = [];
  private auditNodeList: any[] = [];
  private isLoaded = false;

  async created() {
    this.hostId = this.$route.params.hostId;
    this.hostType = this.$route.params.hostType as AuditFlowScope;
    this.auditFlowId = this.$route.params.auditFlowId;
    if (!this.auditFlowId) {
      return;
    }
    let getLogPromise;
    switch (this.hostType) {
      //  外部项目意向书管理
      case AuditFlowScope.ExternalProjectIntention:
        getLogPromise = api.externalProjectIntention.getUserAuditLog;
        break;

      //  外部项目尽调报告
      case AuditFlowScope.DueDiligenceRecord:
        getLogPromise = api.externalDueDiligenceRecord.getUserAuditLog;
        break;

      //  外部项目计划书管理
      case AuditFlowScope.ExternalProject:
        getLogPromise = api.externalProject.getUserAuditLog;
        break;

      //  外部项目-月计划
      case AuditFlowScope.ExternalMonthlyPlan:
        getLogPromise = api.externalMonthlyPlanService.getUserAuditLog;
        break;

      //  外部项目-月总结
      case AuditFlowScope.ExternalMonthlySummarize:
        getLogPromise = api.externalMonthlySummarize.getUserAuditLog;
        break;

      //  外部项目-监测记录
      case AuditFlowScope.ExternalProjectProcessMonitoring:
        getLogPromise =
          api.externalProjectProcessMonitoringService.getUserAuditLog;
        break;

      //  外部项目-中期报告
      case AuditFlowScope.ExternalProjectProgressReport:
        getLogPromise = api.externalProjectProcessReport.getUserAuditLog;
        break;

      //  外部项目-项目自评报告管理
      case AuditFlowScope.ExternalProjectSummaryReport:
        getLogPromise = api.externalProjectSummaryReport.getUserAuditLog;
        break;

      //  外部项目-结项评估报告管理
      case AuditFlowScope.ExternalProjectCloseReport:
        getLogPromise = api.externalProjectCloseReport.getUserAuditLog;
        break;

      //  外部项目-合作方资金申请管理
      case AuditFlowScope.PartnerCapitalApply:
        getLogPromise = api.partnerCapitalApply.getUserAuditLog;
        break;

      //  内部项目意向书管理
      case AuditFlowScope.InternalProject:
        getLogPromise = api.internalProject.getUserAuditLog;
        break;

      //  内部项目月总结管理
      case AuditFlowScope.InternalMonthlySummarize:
        getLogPromise = api.internalMonthlySummarize.getUserAuditLog;
        break;

      //  受益人管理
      case AuditFlowScope.Beneficiary:
        getLogPromise = api.beneficiary.getUserAuditLog;
        break;

      // 项目受益人对应关系
      case AuditFlowScope.ProjectBebeficiaryMapping:
        getLogPromise = api.projectBeneficiaryMapping.getUserAuditLog;
        break;

      //内部项目总结报告
      case AuditFlowScope.InternalProjectSummaryReport:
        getLogPromise = api.internalProjectSummaryReports.getUserAuditLog;
        break;

      //内部项目月计划报告
      case AuditFlowScope.InternalMonthlyPlan:
        getLogPromise = api.internalMonthlyPlan.getUserAuditLog;
        break;

      //支出记录单
      case AuditFlowScope.CapitalExpenseRecord:
        getLogPromise = api.capitalExpenseRecord.getUserAuditLog;
        break;

      //其他资金收入
      case AuditFlowScope.CapitalIncomeRecordFromOther:
        getLogPromise = api.capitalIncomeRecordFromOther.getUserAuditLog;
        break;

      //资金捐赠
      case AuditFlowScope.CapitalIncomeRecordFromDonation:
        getLogPromise = api.capitalIncomeRecordFromDonation.getUserAuditLog;
        break;

      //个人年终总结
      case AuditFlowScope.InternalPersonalYearEndSummary:
        getLogPromise = api.internalPersonalYearEndSummary.getUserAuditLog;
        break;

      //机构审核
      case AuditFlowScope.PartnerApply:
        getLogPromise = api.organizationUnit.getUserAuditLog;
        break;

      //外部项目变更申请
      case AuditFlowScope.ExternalProjectAdjustment:
        getLogPromise = api.externalProjectChangeApply.getUserAuditLog;
        break;

      // 基金申请
      case AuditFlowScope.FundApply:
        getLogPromise = api.fundApply.getUserAuditLog;
        break;

      // 基金修改申请
      case AuditFlowScope.FundModifyApply:
        getLogPromise = api.fundModifyApply.getUserAuditLog;
        break;

      // 基金会项目申请
      case AuditFlowScope.FundProjectApply:
        getLogPromise = api.fundProjectApply.getUserAuditLog;
        break;

      // 基金会项目修改申请
      case AuditFlowScope.FundProjectModifyApply:
        getLogPromise = api.fundProjectModifyApply.getUserAuditLog;
        break;

      // 一般走款业务申请
      case AuditFlowScope.FundAppropriateApply:
        getLogPromise = api.fundAppropriateApply.getUserAuditLog;
        break;

      // 任务
      case AuditFlowScope.UserTask:
        getLogPromise = api.userTask.getUserAuditLog;
        break;

      // 用款申请
      case AuditFlowScope.Application_Payment_Current_Expenses_Le_5:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Current_Expenses_Gt_5:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Expenditure_Charitable_Projects_Le_20:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Expenditure_Charitable_Projects_Gt_20:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Physical_Investment_Le_100:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Physical_Investment_Gt_100:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Investment_Financing_Le_500:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Investment_Financing_Gt_500:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Internal_Business_Reconciliation_Le_20:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Internal_Business_Reconciliation_Gt_20:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;
      case AuditFlowScope.Application_Payment_Other:
        getLogPromise = api.applicationPayment.getUserAuditLog;
        break;

      // 志愿者
      case AuditFlowScope.Volunteer:
        getLogPromise = api.volunteer.getUserAuditLog;
        break;

      //服务需求
      case AuditFlowScope.HelpApply:
        getLogPromise = api.helpApplies.getUserAuditLog;
        break;

      //星级评定记录
      case AuditFlowScope.StarRatingRecord:
        getLogPromise = api.starRatingRecord.getUserAuditLog;
        break;

      //服务记录
      case AuditFlowScope.ServiceRecord:
        getLogPromise = api.serviceRecord.getUserAuditLog;
        break;

      //服务报名
      case AuditFlowScope.ServiceApply:
        getLogPromise = api.serviceApply.getUserAuditLog;
        break;

      //活动报名
      case AuditFlowScope.ActivityApply:
        getLogPromise = api.activityApply.getUserAuditLog;
        break;

      //活动记录
      case AuditFlowScope.ActivityRecord:
        getLogPromise = api.activityRecord.getUserAuditLog;
        break;

      //培训报名
      case AuditFlowScope.TrainApply:
        getLogPromise = api.trainApply.getUserAuditLog;
        break;

      //培训记录
      case AuditFlowScope.TrainRecord:
        getLogPromise = api.trainRecord.getUserAuditLog;
        break;

      default:
        this.$message({message: "不支持的审批流程类型", type: "error"});
        return;
    }

    await getLogPromise({id: Number(this.hostId)}).then((res: any) => {
      if (res && res.length > 0) {
        res[0].show = true;
      }
      this.recordList = res;
      this.isLoaded = true;
    });
  }

  getTimestamp(record: any) {
    let timestamp = "开始于：" + this.formatDate(record.startTime);
    if (record.endTime) {
      timestamp += "    结束于：" + this.formatDate(record.endTime);
    }
    return timestamp;
  }

  formatDate(timestamp: string) {
    if (timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "";
    }
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  toggleDetail(record: any) {
    if (record.show) {
      record.show = false;
    } else {
      record.show = true;
    }
    this.$forceUpdate();
  }

  getStepName(index: any, record: any) {
    let auditMethod = "";
    if (record && record.auditNodeList && record.auditNodeList.length > 0) {
      let node = record.auditNodeList.filter((s: any) => s.index == index)[0];
      let methodEnum = (auditMethod = node.auditMethod);
      if (AuditMethod.AnyOnePassed == methodEnum) {
        auditMethod = "任意一个审核通过";
        record.tooltip =
          "这其中只要有一个审核通过，则会跳转至下一个审核步骤。如果有任意一个审核拒绝，则审核也会自动结束。";
      }
      if (AuditMethod.AllApproved == methodEnum) {
        auditMethod = "全部审核通过";
        record.tooltip =
          "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。如果有任意一个审核拒绝，则审核也会自动结束。";
      }
      if (AuditMethod.AllPassed == methodEnum) {
        auditMethod = "全部审核通过，且所有人都要审核";
        record.tooltip =
          "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。即使有审核拒绝的，也不会立即结束，其他参与者也必须审核。此方式主要是为了保证所有参与者必须给出意见，以提高审批效率。";
      }
      if (AuditMethod.AllParticipateAudit == methodEnum) {
        auditMethod = "全员参与审核，少数服从多数";
        record.tooltip =
          "要求该步骤中的所有参与者必须参与审核。当全部审核完成后，如果通过的人数大于拒绝的人数，则视为审核通过，流程跳到下一步。要求：参与者数量必须为奇数，且不少于3名参与者。";
      }
    }
    return auditMethod;
  }

  getAuditMethod(index: any, record: any) {
    let auditMethod;
    if (record && record.auditNodeList && record.auditNodeList.length > 0) {
      let node = record.auditNodeList.filter((s: any) => s.index == index)[0];
      auditMethod = node.auditMethod;
    }
    return auditMethod;
  }

  getClassName(status: any) {
    let className = "audit-item ";
    switch (status) {
      case AuditUserLogStatus.Pass:
        className += "success";
        break;
      case AuditUserLogStatus.Reject:
        className += "danger";
        break;
      case AuditUserLogStatus.Continue:
        className += "wait";
        break;
      case AuditUserLogStatus.Back:
        className += "info";
        break;
    }
    return className;
  }
}
