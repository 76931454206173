
























































































































































import { Component, Vue } from "vue-property-decorator";
import {
  AuditFlowDto,
  AuditFlowScope,
  AuditFlowType,
  AuditMethod,
  AuditNodeDto,
  AuditUserLogDto,
  AuditUserLogStatus,
  // AuditUserLogType
} from "@/api/appService";
import api from "@/api";
import moment from "moment";

@Component({
  name: "AuditFlowDetail",
})
export default class AuditFlowDetail extends Vue {
  private hostId?: string;
  private hostType?: AuditFlowScope;
  private auditFlowId?: string;

  private auditFlow?: AuditFlowDto;
  private auditLogs?: AuditUserLogDto[] = [];

  recordList: any[] = [
    {
      startTime: "2021-07-13T01:31:33.427Z",
      endTime: "2021-07-13T01:31:33.427Z",
      status: "Ongoing",
      userLogList: [
        {
          auditNodeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          status: "Reject",
          desc: "string",
          creatorUser: {
            userName: "string",
            name: "string",
            surname: "string",
            phoneNumber: "string",
            id: 0,
          },
          creationTime: "2021-07-13T01:31:33.427Z",
          auditStatus: 0,
          expired: true,
          index: 0,
          version: 0,
          auditNode: {
            desc: "string",
            roleName: "string",
            roleId: 0,
            userName: "string",
            userId: 0,
            index: 0,
            auditMethod: "AnyOnePassed",
          },
        },
      ],
      indexList: [{ index: 0, version: 0 }],
      id: 0,
    },
    {
      startTime: "2021-07-13T01:31:33.427Z",
      endTime: "2021-07-13T01:31:33.427Z",
      status: "Ongoing",
      userLogList: [
        {
          auditNodeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          status: "Reject",
          desc: "string",
          creatorUser: {
            userName: "string",
            name: "string",
            surname: "string",
            phoneNumber: "string",
            id: 0,
          },
          creationTime: "2021-07-13T01:31:33.427Z",
          auditStatus: 0,
          expired: true,
          index: 0,
          version: 0,
          auditNode: {
            desc: "string",
            roleName: "string",
            roleId: 0,
            userName: "string",
            userId: 0,
            index: 0,
            auditMethod: "AnyOnePassed",
          },
        },
      ],
      indexList: [{ index: 0, version: 0 }],
      id: 0,
    },
    {
      startTime: "2021-07-13T01:31:33.427Z",
      endTime: "2021-07-13T01:31:33.427Z",
      status: "Ongoing",
      userLogList: [
        {
          auditNodeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          status: "Reject",
          desc: "string",
          creatorUser: {
            userName: "string",
            name: "string",
            surname: "string",
            phoneNumber: "string",
            id: 0,
          },
          creationTime: "2021-07-13T01:31:33.427Z",
          auditStatus: 0,
          expired: true,
          index: 0,
          version: 0,
          auditNode: {
            desc: "string",
            roleName: "string",
            roleId: 0,
            userName: "string",
            userId: 0,
            index: 0,
            auditMethod: "AnyOnePassed",
          },
        },
      ],
      indexList: [{ index: 0, version: 0 }],
      id: 0,
    },
  ];

  private loadForAuditFlow = true;
  private loadForAuditLog = true;

  async created() {
    this.hostId = this.$route.params.hostId;
    this.hostType = this.$route.params.hostType as AuditFlowScope;
    this.auditFlowId = this.$route.params.auditFlowId;
    if (!this.auditFlowId) {
      return;
    }
    let getLogPromise;
    switch (this.hostType) {
      //  外部项目意向书管理
      case AuditFlowScope.ExternalProjectIntention:
        getLogPromise = api.externalProjectIntention.getLogs;
        break;

      //  外部项目尽调报告
      case AuditFlowScope.DueDiligenceRecord:
        getLogPromise = api.externalDueDiligenceRecord.getLogs;
        break;

      //  外部项目计划书管理
      case AuditFlowScope.ExternalProject:
        getLogPromise = api.externalProject.getLogs;
        break;

      //  外部项目-月计划
      case AuditFlowScope.ExternalMonthlyPlan:
        getLogPromise = api.externalMonthlyPlanService.getLogs;
        break;

      //  外部项目-月总结
      case AuditFlowScope.ExternalMonthlySummarize:
        getLogPromise = api.externalMonthlySummarize.getLogs;
        break;

      //  外部项目-监测记录
      case AuditFlowScope.ExternalProjectProcessMonitoring:
        getLogPromise = api.externalProjectProcessMonitoringService.getLogs;
        break;

      //  外部项目-中期报告
      case AuditFlowScope.ExternalProjectProgressReport:
        getLogPromise = api.externalProjectProcessReport.getLogs;
        break;

      //  外部项目-项目自评报告管理
      case AuditFlowScope.ExternalProjectSummaryReport:
        getLogPromise = api.externalProjectSummaryReport.getLogs;
        break;

      //  外部项目-结项评估报告管理
      case AuditFlowScope.ExternalProjectCloseReport:
        getLogPromise = api.externalProjectCloseReport.getLogs;
        break;

      //  内部项目意向书管理
      case AuditFlowScope.InternalProject:
        getLogPromise = api.internalProject.getLogs;
        break;

      //  内部项目月总结管理
      case AuditFlowScope.InternalMonthlySummarize:
        getLogPromise = api.internalMonthlySummarize.getLogs;
        break;

      //  受益人管理
      case AuditFlowScope.Beneficiary:
        getLogPromise = api.beneficiary.getLogs;
        break;

      // 受益人服务记录
      case AuditFlowScope.ServiceRecord:
        getLogPromise = api.beneficiaryServiceRecord.getLogs;
        break;

      // 项目受益人对应关系
      case AuditFlowScope.ProjectBebeficiaryMapping:
        getLogPromise = api.projectBeneficiaryMapping.getLogs;
        break;

      //内部项目总结报告
      case AuditFlowScope.InternalProjectSummaryReport:
        getLogPromise = api.internalProjectSummaryReports.getLogs;
        break;

      //内部项目月计划报告
      case AuditFlowScope.InternalMonthlyPlan:
        getLogPromise = api.internalMonthlyPlan.getLogs;
        break;

      //合作方资金申请
      case AuditFlowScope.PartnerCapitalApply:
        getLogPromise = api.partnerCapitalApply.getLogs;
        break;

      //支出记录单
      case AuditFlowScope.CapitalExpenseRecord:
        getLogPromise = api.capitalExpenseRecord.getLogs;
        break;

      //其他资金收入
      case AuditFlowScope.CapitalIncomeRecordFromOther:
        getLogPromise = api.capitalIncomeRecordFromOther.getLogs;
        break;

      //资金捐赠
      case AuditFlowScope.CapitalIncomeRecordFromDonation:
        getLogPromise = api.capitalIncomeRecordFromDonation.getLogs;
        break;

      //个人年终总结
      case AuditFlowScope.InternalPersonalYearEndSummary:
        getLogPromise = api.internalPersonalYearEndSummary.getLogs;
        break;

      //机构审核
      case AuditFlowScope.PartnerApply:
        getLogPromise = api.organizationUnit.getLogs;
        break;

      //外部项目变更申请
      case AuditFlowScope.ExternalProjectAdjustment:
        getLogPromise = api.externalProjectChangeApply.getLogs;
        break;

      // 基金申请
      case AuditFlowScope.FundApply:
        getLogPromise = api.fundApply.getLogs;
        break;

      // 基金修改申请
      case AuditFlowScope.FundModifyApply:
        getLogPromise = api.fundModifyApply.getLogs;
        break;

      // 基金会项目申请
      case AuditFlowScope.FundProjectApply:
        getLogPromise = api.fundProjectApply.getLogs;
        break;

      // 基金会项目修改申请
      case AuditFlowScope.FundProjectModifyApply:
        getLogPromise = api.fundProjectModifyApply.getLogs;
        break;

      // 一般走款业务申请
      case AuditFlowScope.FundAppropriateApply:
        getLogPromise = api.fundAppropriateApply.getLogs;
        break;

      // 任务
      case AuditFlowScope.UserTask:
        getLogPromise = api.userTask.getLogs;
        break;

      // 用款申请
      case AuditFlowScope.Application_Payment_Current_Expenses_Le_5:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Current_Expenses_Gt_5:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Expenditure_Charitable_Projects_Le_20:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Expenditure_Charitable_Projects_Gt_20:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Physical_Investment_Le_100:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Physical_Investment_Gt_100:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Investment_Financing_Le_500:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Investment_Financing_Gt_500:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Internal_Business_Reconciliation_Le_20:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Internal_Business_Reconciliation_Gt_20:
        getLogPromise = api.applicationPayment.getLogs;
        break;
      case AuditFlowScope.Application_Payment_Other:
        getLogPromise = api.applicationPayment.getLogs;
        break;

      // 志愿者
      case AuditFlowScope.Volunteer:
        getLogPromise = api.volunteer.getLogs;
        break;

      default:
        this.$message({ message: "不支持的审批流程类型", type: "error" });
        return;
    }

    await getLogPromise({ id: Number(this.hostId) }).then((res) => {
      this.auditLogs = res;
      this.recordList = res;
      this.loadForAuditLog = false;
    });
  }

  getTimestamp(record: any) {
    let timestamp = "开始于：" + this.formatDate(record.startTime);
    if (record.endTime) {
      timestamp += "    结束于：" + this.formatDate(record.endTime);
    }
    return timestamp;
  }

  formatDate(timestamp: string) {
    if (timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "";
    }
  }

  get loading() {
    if (!this.auditFlowId) {
      return false;
    }
    return this.loadForAuditFlow || this.loadForAuditLog;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  toggleDetail(record: any) {
    if (record.show) {
      record.show = false;
    } else {
      record.show = true;
    }
    this.$forceUpdate();
  }

  getStepName(indexItem: any, record: any) {
    let auditMethod = "";
    if (record && record.userLogList && record.userLogList.length > 0) {
      let user = record.userLogList.filter(
        (s: any) => s.index == indexItem.index
      )[0];
      let methodEnum = (auditMethod = user.auditNode.auditMethod);
      if (AuditMethod.AnyOnePassed == methodEnum) {
        auditMethod = "任意一个审核通过";
        record.tooltip =
          "这其中只要有一个审核通过，则会跳转至下一个审核步骤。如果有任意一个审核拒绝，则审核也会自动结束。";
      }
      if (AuditMethod.AllApproved == methodEnum) {
        auditMethod = "全部审核通过";
        record.tooltip =
          "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。如果有任意一个审核拒绝，则审核也会自动结束。";
      }
      if (AuditMethod.AllPassed == methodEnum) {
        auditMethod = "全部审核通过，且所有人都要审核";
        record.tooltip =
          "要求该步骤中的所有参与者全部审核通过，只有全部审核通过后，方可跳至下一步的步骤。即使有审核拒绝的，也不会立即结束，其他参与者也必须审核。此方式主要是为了保证所有参与者必须给出意见，以提高审批效率。";
      }
      if (AuditMethod.AllParticipateAudit == methodEnum) {
        auditMethod = "全员参与审核，少数服从多数";
        record.tooltip =
          "要求该步骤中的所有参与者必须参与审核。当全部审核完成后，如果通过的人数大于拒绝的人数，则视为审核通过，流程跳到下一步。要求：参与者数量必须为奇数，且不少于3名参与者。";
      }
    }
    return auditMethod;
  }

  getClassName(status: any) {
    let className = "audit-item ";
    switch (status) {
      case AuditUserLogStatus.Pass:
        className += "success";
        break;
      case AuditUserLogStatus.Reject:
        className += "danger";
        break;
      case AuditUserLogStatus.Continue:
        className += "wait";
        break;
      case AuditUserLogStatus.Back:
        className += "info";
        break;
    }
    return className;
  }
}
