












































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";

import { AuditNodePreDefineDto } from "@/api/appService";

import EditAuditNodePreDefine from "./components/edit-auditNodePreDefine.vue";

@Component({
  name: "AuditNodePreDefineList",
  components: {
    PagedTableView,
    "edit-auditNodePreDefine": EditAuditNodePreDefine,
  },
})
export default class AuditNodePreDefineList extends Vue {
  queryForm = {
    desc: undefined,
  };

  fetchData(params: any) {
    return api.auditNodePreDefine.getAll(params);
  }

  // 新建
  handleCreate() {
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(scope: any) {
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form.id = scope.id;
  }

  // 删除
  async handleDelete(index: number, row: AuditNodePreDefineDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.auditNodePreDefine
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }
}
