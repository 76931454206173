























import { Component, Ref, Vue } from "vue-property-decorator";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabOfTrainRecord from "@/views/myAudit/tabs/tabTrainRecord.vue";
import AuditTabOfTrainApplyCheckIn from "@/views/myAudit/tabs/tabTrainApplyCheckIn.vue";

@Component({
  name: "AuditTabTrain",
  components: {
    AuditTabOfTrainApplyCheckIn,
    AuditCount,
    AuditTabOfTrainRecord,
  },
})
export default class AuditTabTrain extends Vue {
  countDic: any = {
    "train-record":0,
    "train-apply-checkIn":0
  };
  totalCount = 0;

  handleCountChange(target: string, $event: number) {
    this.countDic[target] = $event;
    this.totalCount += $event;
    this.$emit("count-changed", ["train", this.totalCount]);
  }

  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
