




























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import {
  // AuditUserLogType,
  CustomFormType,
} from "@/api/appService";

@Component({
  name: "AuditTabOfInternalServiceRecordBySchool",
  components: { AuditTabPane },
})
export default class AuditTabOfInternalServiceRecordBySchool extends Vue {
  get fetchData() {
    return api.beneficiaryServiceRecord.getMyAll;
  }

  get queryParams() {
    return {
      serviceRecordType: "School",
    };
  }

  async handleAudit(dto: any) {
    let formId = "";
    await api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.SchoolServiceRecord,
        hostId: dto.project.id.toString(),
      })
      .then((res) => {
        formId = res;
      });

    return this.$router.push({
      name: "internalProjectSchoolServiceRecordDetail",
      query: {
        id: dto.id,
        formId: formId,
        type: "School",
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
