




























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
import { AuditFlowScope } from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "AuditTabOfFundNonCashDonation",
  components: {
    AuditTabPane,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class AuditTabOfFundNonCashDonation extends Vue {
  typeList: any = [
    {
      text: "物品捐赠",
      value: 1,
    },
    {
      text: "场地/其他捐赠",
      value: 2,
    },
  ];
  queryForm = {};

  // 获取表数据
  get fetchData() {
    return api.fundNonCashDonation.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "fundNonCashDonationDetail",
      params: {
        id: dto.id,
      },
    });
  }

  // 审核记录
  handleFlowDetail(dto: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundNonCashDonation,
        hostId: dto.id,
        auditFlowId: dto.auditFlowId,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
