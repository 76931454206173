























import { Component, Vue } from "vue-property-decorator";
import AuditTabPane from "../components/AuditTabPane.vue";
import api from "@/api";
// import {AuditUserLogType} from "@/api/appService";

@Component({
  name: "AuditTabOfInternalMonthlyPlan",
  components: { AuditTabPane },
  filters: {
    formatDate(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    },
  },
})
export default class AuditTabOfInternalMonthlyPlan extends Vue {
  get fetchData() {
    return api.internalMonthlyPlan.getMyAll;
  }

  handleAudit(dto: any) {
    return this.$router.push({
      name: "monthlyPlan-detail",
      params: {
        id: dto.id,
      },
    });
  }

  handleCountChange(value: number) {
    this.$emit("count-changed", value);
  }
}
