
























































































import { Component, Vue } from "vue-property-decorator";
import AuditTabGroupOfInternalProject from "@/views/myAudit/internalProject.vue";
import AuditTabGroupOfFinance from "@/views/myAudit/finance.vue";
import AuditTabGroupOfExternalProject from "@/views/myAudit/externalProject.vue";
import AuditTabOfOrganizationUnit from "@/views/myAudit/tabs/tabOrganizationUnit.vue";
import AuditTabGroupOfNormal from "@/views/myAudit/normal.vue";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";
import AuditTabFund from "@/views/myAudit/fund.vue";
import AuditTabFundProject from "@/views/myAudit/fundProject.vue";
import AuditTabTask from "@/views/myAudit/task.vue";
import AuditTabVolunteer from "@/views/myAudit/volunteer.vue";
import AuditTabOfActivityRecord from "@/views/myAudit/tabs/tabActivityRecord.vue";
import AuditTabOfTrainRecord from "@/views/myAudit/tabs/tabTrainRecord.vue";
import AuditTabActivity from "@/views/myAudit/activity.vue";
import AuditTabTrain from "@/views/myAudit/train.vue";
import AuditTabService from "@/views/myAudit/service.vue";
import AuditTabOrganization from "@/views/myAudit/organization.vue";

@Component({
  name: "MyAudit",
  components: {
    AuditTabOrganization,
    AuditTabService,
    AuditTabTrain,
    AuditTabOfTrainRecord,
    AuditTabOfActivityRecord,
    AuditTabGroupOfNormal,
    AuditTabOfOrganizationUnit,
    AuditTabGroupOfExternalProject,
    AuditTabGroupOfFinance,
    AuditTabGroupOfInternalProject,
    AuditCount,
    AuditTabFund,
    AuditTabFundProject,
    AuditTabTask,
    AuditTabVolunteer,
    AuditTabActivity
  }
})
export default class MyAudit extends Vue {
  countDic: any = {
    normal: 0,
    "internal-project": 0,
    "external-project": 0,
    finance: 0,
    fund: 0,
    fundProject: 0,
    task: 0,
    volunteer: 0,
    activity: 0,
    train: 0,
    service: 0,
    organizationUnit:0
  };
  activeName=""
  handleCountChange(target: any) {
    this.countDic[target[0]] = target[1];
  }
  created() {
    if (window.sessionStorage.getItem("auditFirstTabName")){
      this.activeName=window.sessionStorage.getItem("auditFirstTabName")!
    }
  }
  hasAuditPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
  handleTabChange(e:any){
    console.log("handleTabChange",e.name)
    window.sessionStorage.setItem("auditFirstTabName",e.name);
  }
  handleTab2Change(e:any){
    console.log("handleTabChange",e)
    window.sessionStorage.setItem("auditSecondTabName",e.name);
  }
}
