






















































import { Component, Model, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import {
  AuditFlowDto,
  AuditFlowUpdateSortPostDto,
  GuidEntityDto,
  UserDto,
} from "../../../../api/appService";
import api from "@/api";
import { TriggerRuleDto } from "@/views/auditFlow/components/triggerRules/triggerRules.vue";

export interface AuditFlowNeedFieldDto {
  key: string;
  id: string;
  isDefaultRule: boolean;
  triggerRules: TriggerRuleDto[];
}

@Component({
  name: "AuditFlowDraggable",
  components: {
    draggable,
  },
})
export default class AuditFlowDraggable extends Vue {
  @Model("change", { required: true, type: Array, default: () => [] })
  readonly auditFlows!: AuditFlowDto[];

  private auditFlowNeedFields: AuditFlowNeedFieldDto[] = [];

  created() {
    if (this.auditFlows && this.auditFlows.length > 0) {
      this.auditFlowNeedFields = [];
      this.auditFlows.forEach((m) => {
        let auditFlowNeedField = {
          key: m.key!,
          id: m.id!,
          isDefaultRule: true,
          triggerRules: [],
          title: m.title,
          status: m.status,
        };
        if (m.ruleConfig && m.ruleConfig != "[]") {
          auditFlowNeedField.isDefaultRule = false;
          auditFlowNeedField.triggerRules = JSON.parse(m.ruleConfig);
        }
        this.auditFlowNeedFields.push(auditFlowNeedField);
      });
    }
  }

  onMove(e: any) {
    console.log(e.relatedContext.element);
    if (e.relatedContext.element == "") return false;
    return true;
  }

  private async dragEnd(e: any) {
    // console.log(this.e);
    console.log(this.auditFlowNeedFields);
    if (this.auditFlowNeedFields && this.auditFlowNeedFields.length > 0) {
      let data: AuditFlowUpdateSortPostDto = { ids: [] };
      this.auditFlowNeedFields.forEach((item: any) => {
        data.ids!.push(item.id);
      });
      await api.auditFlowService.updateSort({ body: data });
    }
    //updateSort
    // await api.auditFlowService.updateSort()
  }

  private async dragStart(e: any) {
    console.log(e);
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
    }
  }

  // 编辑
  handleEdit(index: number, item: any) {
    this.$router.push({
      name: "auditFlowConfiguration",
      params: {
        key: item.key,
      },
      query: {
        isSupportRules: "true",
        auditFlowId: item.id,
      },
    });
  }

  // 删除
  handleDelete(index: number, item: any) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      api.auditFlowService.delete({ id: item.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  //
  changeStatus(item: any) {
    let data: GuidEntityDto = { id: item.id };
    api.auditFlowService.active({ body: data }).then(() => {
      console.log("auditFlow active processed.");
    });
  }
}
