









































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";
import {
  AuditFlowDefinitionDetailDto,
  AuditFlowDefinitionListDto,
} from "@/api/appService";

@Component({
  name: "AuditFlowMain",
  components: {},
})
export default class AuditFlowMain extends Vue {
  queryForm = {
    name: "",
  };

  auditFlowDefinitionList: AuditFlowDefinitionListDto[] = [];

  created() {
    this.fetchData();
  }

  // 获取表数据
  async fetchData() {
    await api.auditFlowDefinition
      .getAll({ displayName: this.queryForm.name })
      .then((res) => {
        this.auditFlowDefinitionList = res;
      });
  }

  async active(id: any) {
    await api.auditFlowService.active({ body: { id: id } });
    await this.fetchData();
  }

  handleEdit(item: AuditFlowDefinitionDetailDto) {
    if (item.isSupportRules) {
      //go audit flow configuration list
      this.$router.push({
        name: "auditFlowConfigurationList",
        params: {
          key: item.key!,
        },
        query: {
          title: item.displayName,
          auditFlowId: item.auditFlowId,
        },
      });
    } else {
      //go audit flow configuration
      this.$router.push({
        name: "auditFlowConfiguration",
        params: {
          key: item.key!,
        },
        query: {
          id: item.auditFlowId,
          isSupportRules: "false",
          auditFlowId: item.auditFlowId,
        },
      });
    }
  }

  // // 删除
  // async handleDelete(index: number, row: CmsFriendLinkDto) {
  //   this.$confirm("你确定删除吗?", "提示").then(async () => {
  //     await api.cmsFriendLink
  //       .delete({
  //         id: row.id
  //       })
  //       .then(res => {
  //         this.$message({
  //           type: "success",
  //           message: "删除成功!"
  //         });
  //       });
  //   });
  // }

  handleSearch() {
    this.fetchData();
  }
}
